// let webSocket = null;
// let socketOpen = false;
//
// // 发送消息
// export const doSend = (message) => {
//
//     if (socketOpen) {
//         console.log(message)
//         webSocket.send(message)
//     }
// }
//
// // 初始化websocket
// export const contactSocket = () => {
//     if ("WebSocket" in window) {
//         webSocket = new WebSocket(`ws://192.168.2.46:8094/payment`);
//         webSocket.onopen = function () {
//             console.log("连接成功！");
//             socketOpen = true
//         };
//         webSocket.onmessage = function (evt) {
//             console.log(evt)
//             var received_msg = evt.data;
//             console.log("接受消息：" + received_msg);
//         };
//         webSocket.onclose = function () {
//             console.log("连接关闭！");
//         };
//         webSocket.onerror = function () {
//             console.log("连接异常！");
//         };
//     }
// }
//
// export const closeSocket=()=>{
//     webSocket.close()
//     webSocket=null
// }

// let limitConnect = 3
// let timeConnect = 0
// let timerWs = null
//
// let Socket = ''
// // 缓存ip
// let ip = ''
// /**
//  * 建立websocket连接
//  * @param {string} url ws地址
//  */
// export const createSocket = url => {
//     ip = url
//     Socket = new WebSocket(url)
//     // 发送心跳
//     Socket.onopen = onopenWS
//     // 接收消息
//     Socket.onmessage = onmessageWS
//     // 断开重连
//     Socket.onclose = oncloseWS
// }
//
// // 断开连接
// export const closeSoket = () => {
//     if (Socket.readyState === 1) {
//         Socket.close()
//         Socket = null
//     }
// }
//
// /** 打开WS之后发送心跳 */
// const onopenWS = () => {
//     sendPing()
// }
//
// /** WS数据接收统一处理 （通过事件订阅处理）*/
// const onmessageWS = e => {
//     window.dispatchEvent(
//         new CustomEvent('onmessageWS', {
//             detail: {
//                 data: e.data
//             }
//         })
//     )
// }
//
// /** 断开重连 */
// const oncloseWS = () => {
//     reconnectWs()
// }
// /**
//  * @description 重连机制
//  */
// const reconnectWs = () => {
//     if (limitConnect > 0) {
//         limitConnect--
//         timeConnect++
//         console.log(`第${timeConnect}次重连`)
//         setTimeout(() => {
//             createSocket(ip)
//         }, 2000)
//     } else {
//         console.log('webSocket连接超时')
//     }
// }
//
// /**
//  * 发送数据
//  * @param {any} message 需要发送的数据
//  */
// export const sendWSPush = message => {
//     return new Promise((reslove, reject) => {
//         Socket.send(JSON.stringify(message))
//         reslove(Socket.readyState)
//     }).catch(error=>{})
// }
//
// /** 发送心跳
//  * @param {number} time 心跳间隔毫秒10s 默认5min=5*60s 1s=1000ms
//  * @param {string} ping 心跳名称 默认字符串ping
//  */
// export const sendPing = (time = 1000 * 10, ping = 'ping') => {
//     if (timerWs) {
//         clearInterval(timerWs)
//         timerWs = null
//     }
//     timerWs = setInterval(() => {
//         Socket.send(ping)
//     }, time)
// }

// Socket.readyState状态码：0:正在连接；1:连接成功，可以通信  2：表示连接正在关闭 3：表示连接已经关闭，或者打开连接失败


let limitConnect = 3
let timeConnect = 0
let timerWs = null // ping长连接 定时器
let SendMessage = ''

let Socket = ''
let socketUrl=`wss://www.biaoxiaozhu.cn/payment`
// let socketUrl = `wss://www.test.biaoxiaozhu.cn/payment`

// 创建一个socket连接

export const connectSocket = url => {
    Socket = new WebSocket(socketUrl)
    // 连接心跳
    Socket.onopen = onOpenSocket
    // 接受消息
    Socket.onmessage = getMessage
    // 断线重连
    Socket.onclose = onCloseWs
}


const onOpenSocket = () => {
    // 发送心跳
    socketSendPing()
}

// 接受消息
const getMessage = (e) => {
    window.dispatchEvent(
        new CustomEvent('getMessage', {
            detail: {
                data: e.data
            }
        }))


    // if (e.data==='pong'){
    //   closeSocketWs()
    // }
}

const onCloseWs = () => {
    reconnectWs()
}

export function reconnectWs() {
    if (limitConnect > 0) {
        limitConnect--
        timeConnect++
        setTimeout(() => {
            connectSocket()
        }, 2000)
    } else {
        console.log('webSocket timeout')
    }
}

// 断开连接
export function closeSocketWs() {

    if (Socket) {
        Socket.close()
        Socket = null
        limitConnect = 0
        console.log('close')
    }

}

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
export const sendWSPush = message => {
    if (Socket.readyState === 1) {
        Socket.send(message)
    } else {
        SendMessage = message
    }
    // if (Socket){
    //   return new Promise((reslove, reject) => {
    //       console.log(reslove.readyState,reject)
    //
    //
    //
    //     Socket.send(message)
    //     reslove(Socket.readyState)
    //   }).catch(error=>{})
    // }
}


export function socketSendPing() {

    if (SendMessage === '') {
        let time = 3000;
        let ping = "ping"
        if (timerWs) {
            clearInterval(timerWs)
            timerWs = null
        }
        timerWs = setInterval(() => {
            if (Socket) {
                Socket.send(ping)
            } else {
                connectSocket()
            }
        }, time)
    } else {
        Socket.send(SendMessage)
        SendMessage = ''
    }


}





















