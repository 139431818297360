<template>
  <div id="app">
        <pageIndex v-if="checkedPhone"></pageIndex>
        <views v-else></views>
  </div>
</template>

<script>
  import views from "./views/page_views/index";
  import pageIndex from './pages/App'
  import {getCaption} from "@/utils/parse";
  import {encryptForCzx} from "@/utils/AES";
  import {getRandomCode} from "@/utils/RandomCode";

  export default {
    components:{views,pageIndex},
    data(){
      return{
        checkedPhone:false,
        windowWidth: document.documentElement.clientWidth, // 实时屏幕宽度
        width:0,
        spinning:false
        // windowHeight: document.documentElement.clientHeight // 实时屏幕高度
      }
    },

    methods:{
      toPage(){
        let url = sessionStorage.getItem('pathUrl')
        if (this.windowWidth<900){
          let reg = RegExp('code=')
          if (reg.test(location.href)){
            let s =location.href.split('#/')[0].split('/?')[1].split('&')
            let params={code:s[0].split('code=')[1],state:decodeURIComponent(s[1].split('state=')[1]) }
            let Rage= RegExp('actives')
            let phoneRage=RegExp('userPrice')
            if (Rage.test(location.href)){
              this.$router.push({path:'phoneActive',query:params})
            }else if (phoneRage.test(location.href)) {
              this.$router.push({path:'phoneRechargePay',query:params})
            }else{
              this.$router.push({path:'phonePay',query:params})
            }
          }else{
            if (RegExp('/search').test(url)){
              this.$router.push({path:'/phoneKeyword',query:{bidType:0,categoryId:'',keyWord:'',maxPage:'',minPage:'',type:0}})
              this.spinning=false
            }
            else if (RegExp('/content').test(url)){
              this.$router.push({path:'/phoneContent',query:{doc:url.split('doc=')[1]}})
              this.spinning=false
            }
            else if (RegExp('/phoneKeyword').test(url)){
              this.$router.push(url.split('#/')[1])
              this.spinning=false
            }
            else if (RegExp('/phone').test(url)){
              this.$router.push(url.split('#/')[1])
              this.spinning=false
            }else{
              this.$router.push('/phone')
              this.spinning=false
            }
          }
        }
        else{
          if (RegExp('/phoneKeyword').test(url)){
            this.$router.push({path:'/search',query:{bidType:0,categoryId:'',keyWord:'',maxPage:'',minPage:'',type:0}})
          }
          else if (RegExp('/phoneContent').test(url)){
            this.$router.push({path:'/content',query:{doc:url.split('doc=')[1]}})
          }else{
            if (!(RegExp('/phone').test(url))){
              this.$router.push(url.split('#/')[1])
            }else{
              this.$router.push('/')
            }
          }
        }
      }
    },

    mounted() {
      if (localStorage.getItem('rechargeUrl')){
          if (this.windowWidth>1000){
              localStorage.removeItem('rechargeUrl')
              this.toPage()
          }else{
              this.$router.push({path:'phoneRechargePay',query:JSON.parse(localStorage.getItem('rechargeUrl'))})
          }
      }else{
          // 如果页面小于900判定为手机端，如果是搜索页面跳到手机端的搜索页面，如果是 详情页跳详情页，如果是首页跳首页


          let flag = navigator.userAgent.match( /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
          if (flag){
              this.spinning=true
              this.toPage()
          }else{
              window.onresize=() =>{
                  return(()=>{
                      this.windowWidth = document.documentElement.clientWidth
                      this.toPage()
                  })()
              }
          }
      }


    },

    watch:{
      $route:{
        handler(newValue,oldValue){
          sessionStorage.setItem('pathUrl',location.href)
          let reg=RegExp('phone')
          if (reg.test(newValue.path)){
            this.checkedPhone=true
            // localStorage.setItem('historyUrl',getCaption(location.href,'#/'))
          }else {
            localStorage.setItem('historyUrl',getCaption(location.href,'#/'))
            this.checkedPhone=false
          }
        },
        deep: true,
        immediate:true
      }
    },
  }
</script>
