<template>
    <div>
        <a-drawer
                title="我的消息"
                placement="right"
                :closable="false"
                :visible="messageDraw"
                :after-visible-change="afterVisibleChange"
                @close="onClose"
                width="500px"
        >
            <div class="message_p">
                <div class="message_draw">

                    <div class="message_title">
                        <span style="color: #333"><a-icon type="bell" /> 充值标币   <a-badge :dot="true"></a-badge></span>
                        <span>2022-12-31</span>
                    </div>

                    <div class="message_content">

                        <a-collapse accordion default-active-key="0" :bordered="false">
                            <template #expandIcon="props">
                                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="标小助--标币充值服务" :style="customStyle">
                                <span v-html="text"></span>
                            </a-collapse-panel>
                        </a-collapse>

                    </div>
                </div>
                <div class="message_draw">

                    <div class="message_title">
                        <span style="color: #333"><a-icon type="bell" /> 充值标币   <a-badge :dot="true"></a-badge></span>
                        <span>2022-12-31</span>
                    </div>

                    <div class="message_content">

                        <a-collapse accordion default-active-key="0" :bordered="false">
                            <template #expandIcon="props">
                                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="标小助--标币充值服务" :style="customStyle">
                                <span v-html="text"></span>
                            </a-collapse-panel>
                        </a-collapse>

                    </div>
                </div>
                <div class="message_draw">

                    <div class="message_title">
                        <span style="color: #333"><a-icon type="bell" /> 充值标币   <a-badge :dot="true"></a-badge></span>
                        <span>2022-12-31</span>
                    </div>

                    <div class="message_content">

                        <a-collapse accordion default-active-key="0" :bordered="false">
                            <template #expandIcon="props">
                                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="标小助--标币充值服务" :style="customStyle">
                                <span v-html="text"></span>
                            </a-collapse-panel>
                        </a-collapse>

                    </div>
                </div>
                <div class="message_draw">

                    <div class="message_title">
                        <span style="color: #333"><a-icon type="bell" /> 充值标币   <a-badge :dot="true"></a-badge></span>
                        <span>2022-12-31</span>
                    </div>

                    <div class="message_content">

                        <a-collapse accordion default-active-key="0" :bordered="false">
                            <template #expandIcon="props">
                                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="标小助--标币充值服务" :style="customStyle">
                                <span v-html="text"></span>
                            </a-collapse-panel>
                        </a-collapse>

                    </div>
                </div>
                <div class="message_draw">

                    <div class="message_title">
                        <span style="color: #333"><a-icon type="bell" /> 充值标币   <a-badge :dot="true"></a-badge></span>
                        <span>2022-12-31</span>
                    </div>

                    <div class="message_content">

                        <a-collapse accordion default-active-key="0" :bordered="false">
                            <template #expandIcon="props">
                                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="标小助--标币充值服务" :style="customStyle">
                                <span v-html="text"></span>
                            </a-collapse-panel>
                        </a-collapse>

                    </div>
                </div>
                <div class="message_draw">

                    <div class="message_title">
                        <span style="color: #333"><a-icon type="bell" /> 充值标币   <a-badge :dot="true"></a-badge></span>
                        <span>2022-12-31</span>
                    </div>

                    <div class="message_content">

                        <a-collapse accordion default-active-key="0" :bordered="false">
                            <template #expandIcon="props">
                                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="标小助--标币充值服务" :style="customStyle">
                                <span v-html="text"></span>
                            </a-collapse-panel>
                        </a-collapse>

                    </div>
                </div>
                <div class="message_draw">

                    <div class="message_title">
                        <span style="color: #333"><a-icon type="bell" /> 充值标币   <a-badge :dot="true"></a-badge></span>
                        <span>2022-12-31</span>
                    </div>

                    <div class="message_content">

                        <a-collapse accordion default-active-key="0" :bordered="false">
                            <template #expandIcon="props">
                                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="标小助--标币充值服务" :style="customStyle">
                                <span v-html="text"></span>
                            </a-collapse-panel>
                        </a-collapse>

                    </div>
                </div>
                <div class="message_draw">

                    <div class="message_title">
                        <span style="color: #333"><a-icon type="bell" /> 充值标币   <a-badge :dot="true"></a-badge></span>
                        <span>2022-12-31</span>
                    </div>

                    <div class="message_content">

                        <a-collapse accordion default-active-key="0" :bordered="false">
                            <template #expandIcon="props">
                                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="标小助--标币充值服务" :style="customStyle">
                                <span v-html="text"></span>
                            </a-collapse-panel>
                        </a-collapse>

                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    export default {
        props:['messageDraw'],
        name: "message",
        data(){
          return{
              text: `您在2023年12月31号9:00成功充值<b style="color: #F1040A;">100</b> 个标币，您可以直接去购买文档，或者点击此链接去查询你想要的文档信息`,
              customStyle:
                  'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
          }
        },
        methods: {
            afterVisibleChange(val) {
                console.log('visible', val);
            },
            onClose() {
                this.$emit('messageClose',true)
            },
        },
    }
</script>
<style scoped>
    >>>.ant-collapse-item{
        margin-bottom: 0px !important;
    }
</style>

<style scoped lang="scss">
.message_p{
    width: 100%;
    min-height: 800px;
    .message_draw{
        width: 100%;
        min-height: 30px;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 0 0 0;
        box-sizing: border-box;
        .message_title{
            width: 100%;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>