<template>
    <div>
        <login :login-show="loginShow" @submitLoginClose="submitLoginClose" @loginSuccess="loginSuccess"></login>
        <reg :reg-show="regShow" @submitRegClose="submitRegClose" @loginSuccess="loginSuccess"></reg>
                <pay :recharge="recharge" @payClose="payClose"></pay>
        <Message :message-draw="messageShow" @messageClose="messageClose"></Message>
        <!--        平板或者为pc-->
        <div :style="pathStatus===false ? {boxShadow:'none'}:null" :class="navTop ? 'nav_main' : 'nav_main_leave'">
            <div class="nav_top">
                <div class="nav_center">
                    <!-- logo-->
                    <div @click="$router.push('/')" class="nav_logo"><img :src="logoLeave" alt=""></div>
                    <div class="nav_title" v-if="navTop">
                        <div v-for="(item,index) in titleList" :key="index">
                            <div class="nav_btn">
                                <a-button type="link" @click="choseDown(item)"
                                          :class="active===item.id ? 'btn_active': 'btn'">{{ item.name }}
                                </a-button>
                            </div>
                        </div>
                    </div>

                    <div class="nav_title" v-else>
                        <div class="nav_search">
                            <div class="nav_search_center_title">
                                <a-button type="link" @click="backToHome" class="btn">首页</a-button>
                                <a-dropdown class="downValue">
                                    <a class="ant-dropdown-link search_value"
                                       @click="e => e.preventDefault()">{{ downName.name }}
                                        <a-icon style="margin-left: 10px" type="down"/>
                                    </a>
                                    <a-menu slot="overlay">
                                        <a-menu-item v-for="(item,index) in downList" :key="index">
                                            <a class="search_value" @click="downChose(item)"
                                               href="javascript:(0);">{{ item.name }}</a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>

                            <div class="title_ghostWrite" @click="$router.push({path: '/custom'})">
                                <span>方案定制</span>
                            </div>

                            <div class="nav_search_title">
                                <div class="search_left">
                                    <a-dropdown>
                                        <a class="ant-dropdown-link search_value"
                                           @click="e => e.preventDefault()">{{ downName.name }}
                                            <a-icon style="margin-left: 10px" type="down"/>
                                        </a>
                                        <a-menu slot="overlay">
                                            <a-menu-item v-for="(item,index) in downList" :key="index">
                                                <a class="search_value" @click="downChoseIpt(item)" href="javascript:;">{{
                                                    item.name
                                                    }}</a>
                                            </a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                </div>
                                <div class="search_center">
                                    <a-input
                                            style="font-size: 14px;font-weight: 500;border: none;outline: none;box-shadow: none"
                                            v-model="iptValue" @pressEnter="searchCategory" type="text"
                                            placeholder="请输入关键字进行搜索"></a-input>
                                </div>
                                <div class="search_right">
                                    <a-button @click="searchCategory" type="link" icon="search">
                                        搜 索
                                    </a-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="navTop ? 'nav_login':'nav_login_leave' ">
                        <a-button type="link" @click="submitLogin" v-if="login===false">
                            <a-icon type="user"/>
                            登录
                        </a-button>
                        <a-button id="reg" type="link" @click="submitReg" v-if="login===false">
                            注册
                        </a-button>
                        <!--                        <a-button id="rechager" type="link" v-if="login"> <a-icon type="money-collect" /> 充值</a-button>-->
                        <div @click="readMessage" class="recharge_nav_top" v-if="login">
                            <a-badge :count="dot">
                                <img src="../../assets/image/img/icon_msg_top.png" alt="">
                            </a-badge>
                            <a style="color: #333333;margin-left: 10px;font-size: 14px"
                               href="javascript:void (0)">消息</a>
                        </div>
                        <div class="recharge_nav_top" @click="recharge=true" v-if="login">
                            <a href="javascript:void (0)" style="font-size: 14px;">
                                <img style="margin-right: 5px" src="../../assets/image/img/icon_recharge.png" alt="">
                                <span style="color: #f16363;">充值</span>
                            </a>
                        </div>

                        <a-popover v-if="login" placement="bottom">
                            <template slot="content">
                                <div class="nav_user_top">
                                    <div class="nav_top">
                                        <div class="nav_left">
                                            <img style="height: 40px;border-radius: 50%;width: 40px;"
                                                 :src="headIcon ? headIcon : icon" alt="">
                                            <div class="nav_top_user">
                                                <div class="user">
                                                    {{
                                                    form.nickName ? form.nickName.length > 11 ? form.nickName.slice(0, 11) + '...' : form.nickName : form.phone
                                                    }}
                                                </div>
                                                <div class="fund">标币：{{ form.fund }}</div>
                                            </div>
                                        </div>
                                        <div class="nav_top_right">
                                            <a-button type="link" @click="loginOut" icon="logout">退出登录</a-button>
                                        </div>
                                    </div>
                                    <div class="nav_bottom">
                                        <div class="nav_list">
                                            <a-button @click="navToUser(0)" type="link">
                                                <img src="../../assets/image/img/icon_cc1.png" alt="">
                                            </a-button>
                                            <div>用户中心</div>
                                        </div>
                                        <div class="nav_list">
                                            <a-button @click="navToUser(3)" type="link">
                                                <img src="../../assets/image/img/icon_cc3.png" alt="">
                                            </a-button>
                                            <div>开票管理</div>
                                        </div>
                                        <div class="nav_list">
                                            <a-button @click="navToUser(2)" type="link">
                                                <img src="../../assets/image/img/icon_cc2.png" alt="">
                                            </a-button>
                                            <div>我的购买</div>
                                        </div>

                                    </div>
                                </div>
                            </template>
                            <a href="javascript:void (0)" id="toUser" type="link">
                                <!--                                <a-avatar :size="28" class="avatar"  icon="user"/>-->
                                <img style="height: 28px;border-radius: 50%;width: 28px;" class="avatar" v-if="login"
                                     :src="headIcon ? headIcon : icon" alt="">
                                <span style="font-size: 14px">{{
                                    form.nickName ? form.nickName.length > 11 ? form.nickName.slice(0, 11) + '...' : form.nickName : form.phone
                                    }}</span>
                            </a>
                        </a-popover>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import data from "@/data/data";
import login from "@/views/page_login/login";
import '../style/navTitle.scss'
import reg from "@/views/page_login/reg";
import {decrypt} from "@/utils/AES";
import {navigateUrl} from "@/utils/resToData";
import pay from "@/views/page_pay/pay";
import pay2 from "@/views/page_pay/pay2";
import Message from '@/views/page_message/message'
import {bulkRead, messageList, unreadCount} from "@/api/page/center";
import {getUserInfo} from "@/api/page/sso";
import {searchKey} from "@/api/phone/promoteKey";
import {addBehavior} from "@/data/accessKey";
import {closeSocketWs} from "@/utils/websocket";

export default {
    name: "navTitle",
    props: ['navTop', 'login', 'pathStatus'],
    components: {login, reg, pay, pay2, Message},
    data() {
        return {
            active: 0,
            dot: 0,
            downName: {id: 0, name: '全部分类'},
            titleList: data.navList,
            logo: data.logoTop, //目前为置顶的状态
            logoLeave: data.logo,
            form: {phone: ''},
            downList: [
                {id: 0, name: '全部分类'},
                {id: 1, name: '投标方案'},
                {id: 2, name: '合同范本'},
                {id: 3, name: '管理制度'},
            ],
            loginShow: false,
            regShow: false,
            iptValue: '', //搜索
            locationKeyWord: '',
            recharge: false,
            messageShow: false,
            headIcon: '',
            beginTime: Date.parse(new Date()),
            icon: require('../../assets/image/img/icon-avera.png')
        }
    },
    methods: {
        onClick({key}) {
            console.log(`Click on item ${key}`);
        },

        backToHome() {
            if (this.$route.path === '/active') {
                this.$router.push('/')
            } else {
                if (this.$route.path !== '/') {
                    localStorage.setItem('toPath', JSON.stringify({id: 0, name: '全部分类'}))
                    // this.choseDown(  {id:0,name:'首页',url:'/',active:0},)
                    this.active = 0
                    this.$bus.$emit('changeCategoryToHome', {id: 0, name: '首页', url: '/', active: 0})
                    localStorage.setItem('keyWord', JSON.stringify({
                        type: 0,
                        keyWord: '',
                        bidType: '',
                        categoryId: '',
                        page: ''
                    }))
                    navigateUrl('/')
                }
            }
        },

        // 搜索页
        searchCategory() {
            if (this.$route.path === '/search') {
                this.$bus.$emit('searchValue', {searchValue: this.iptValue})
                this.$router.push({
                    path: 'search',
                    query: {type: this.downName.id, keyWord: this.iptValue, bidType: '', categoryId: '', page: ''}
                })
                searchKey(this.iptValue)
                addBehavior('PC用户搜索数据', '用户搜索：' + this.iptValue, location.href, this.beginTime, Date.parse(new Date()))
            } else {
                this.$bus.$emit('searchValue', {searchValue: this.iptValue})
                navigateUrl('/search',
                    {
                        type: this.downName.id,
                        keyWord: this.iptValue,
                        bidType: '',
                        categoryId: '',
                        maxPage: '',
                        minPage: ''
                    },
                    '用户搜索', '该用户搜索：' + this.iptValue, location.href, this.beginTime
                )
                searchKey(this.iptValue)
            }
            localStorage.setItem('keyWord', JSON.stringify({
                type: this.downName.id,
                keyWord: this.iptValue,
                bidType: '',
                categoryId: '',
                page: ''
            }))
        },

        // 搜索
        searchValues(e) {
            if (this.$route.path === '/search') {
                this.$bus.$emit('searchValue', {searchValue: ''})
                this.$router.push({
                    path: 'search',
                    query: {type: e, keyWord: '', bidType: '', categoryId: '', page: ''}
                })
                addBehavior('PC用户搜索数据', '用户搜索：' + this.iptValue, location.href, this.beginTime, Date.parse(new Date()))
            } else {
                this.$bus.$emit('searchValue', {searchValue: ''})
                navigateUrl('/search', {
                    type: e,
                    keyWord: '',
                    bidType: '',
                    categoryId: '',
                    maxPage: '',
                    minPage: ''
                }, '用户搜索', '该用户搜索：' + this.iptValue, location.href, this.beginTime)
            }
            localStorage.setItem('keyWord', JSON.stringify({
                type: e,
                keyWord: '',
                bidType: '',
                categoryId: '',
                page: ''
            }))
        },
        choseDown(e) {
            if (e !== 4 || e !== 5) {
                this.active = e.id
            }
            this.$bus.$emit('changeCategoryToHome', e)
            switch (e.id) {
                case 0: {
                    this.downName = {id: 0, name: '全部分类'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    if (this.$route.path === '/' || this.$route.path === '/model' || this.$route.path === '/active') {
                        this.$router.push({path: '/'})
                    } else {
                        this.searchValues(0)
                    }
                    break
                }
                case 1: {
                    this.downName = {id: 1, name: '投标方案'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    this.searchValues(1)
                    break
                }
                case 2: {
                    this.downName = {id: 2, name: '合同范本'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    this.searchValues(2)
                    break
                }
                case 3: {
                    this.downName = {id: 3, name: '管理制度'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    this.searchValues(3)
                    break
                }
                case 4: {
                    this.downName = {id: 1, name: '投标方案'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    this.$router.push({path: '/custom'})
                    break
                }
                case 5: {
                    this.downName = {id: 1, name: '投标方案'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    this.$router.push({path: '/custom'})
                    break
                }
            }
        },

        choseDownHome(e) {
            this.active = e.id
            this.$bus.$emit('changeCategoryToHome', e)
            switch (e.id) {
                case 0: {
                    this.downName = {id: 0, name: '全部分类'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    break
                }
                case 1: {
                    this.downName = {id: 1, name: '投标方案'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    break
                }
                case 2: {
                    this.downName = {id: 2, name: '合同范本'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    break
                }
                case 3: {
                    this.downName = {id: 3, name: '管理制度'};
                    localStorage.setItem('toPath', JSON.stringify(this.downName))
                    break
                }
            }
        },

        downChose(e) {
            this.downName = e;
            this.active = e.id
            this.$bus.$emit('changeCategoryToHome', e);
            this.choseDown(e)
        },

        downChoseIpt(e) {
            this.downName = e;
            this.active = e.id
            this.$bus.$emit('changeCategoryToHome', e);
        },

        submitLogin() {
            this.loginShow = true
        },

        submitLoginClose() {
            this.loginShow = false
        },

        submitRegClose() {
            this.regShow = false
        },

        submitReg() {
            this.regShow = true
        },

        loginSuccess() {
            let s = localStorage.getItem('userInfo')
            let n = localStorage.getItem('accessKey')
            if (s && n) {
                let d = JSON.parse(decrypt(s))
                this.form = d
                this.headIcon = d.icon
            }
            this.$emit('loginGetToken', true)
        },

        loginOut() {
            this.$emit('loginOutInfo', true)
            this.$bus.$emit('loginOut', true)
            localStorage.removeItem('accessKey')
            localStorage.removeItem('userInfo')
            if (this.$route.path === '/user') {
                this.$router.push('/')
            }
        },

        navToUser(e) {
            if (this.$route.path === '/user') {
                this.$router.push({path: '/user', query: {checkId: e}}).catch(err => {
                })
                this.$bus.$emit('checkId', e)
            } else {
                navigateUrl('/user', {checkId: e})
            }
        },

        // 关闭页面
        payClose() {
            // setTimeout(()=>{
            //     closeSocketWs()
            // },300000)
            this.recharge = false
        },

        // 消息页面关闭
        messageClose() {
            this.messageShow = false
        },

        // 获取消息
        messageRead() {
            unreadCount().then(res => {
                this.dot = res
            })

        },

        readMessage() {
            bulkRead().then(res => {
                this.messageRead()
                if (this.$route.path === '/user') {
                    this.$router.push({path: '/user', query: {checkId: 4}}).catch(err => {
                    })
                    this.$bus.$emit('checkId', 4)
                } else {
                    navigateUrl('/user', {checkId: 4})
                }
            })
        }
    },

    mounted() {
        this.loginSuccess()

        this.$bus.$on('changeCategory', data => {
            this.iptValue = ''
            this.choseDownHome(data)
        })

        this.$bus.$on('changeIptValue', data => {
            this.iptValue = data
        })

        this.$bus.$on('loginSuccessInfo', data => {
            this.loginSuccess()
        })

        this.messageRead()

        this.$bus.$on('readMsg', data => {
            this.messageRead()
        })

        this.$bus.$on('accountSuccess', data => {
            this.$emit('loginOutInfo', true)
            this.$bus.$emit('loginOut', true)
            localStorage.removeItem('accessKey')
            localStorage.removeItem('userInfo')
        })

        this.$bus.$on('chargeSuccess', data => {
            getUserInfo().then(res => {
                localStorage.setItem('userInfo', res)
                let s = localStorage.getItem('userInfo')
                let d = JSON.parse(decrypt(s))
                this.form = d
                this.headIcon = this.form.icon
            })
        })

        this.$bus.$on('buySuccess', data => {
            getUserInfo().then(res => {
                localStorage.setItem('userInfo', res)
                let s = localStorage.getItem('userInfo')
                let d = JSON.parse(decrypt(s))
                this.form = d
                this.headIcon = this.form.icon
            })
        })

        this.$bus.$on('PayDocSuccess', data => {
            getUserInfo().then(res => {
                localStorage.setItem('userInfo', res)
                let s = localStorage.getItem('userInfo')
                let d = JSON.parse(decrypt(s))
                this.form = d
                this.headIcon = this.form.icon
            })
        })


        if (localStorage.getItem('keyWord')) {
            this.iptValue = JSON.parse(localStorage.getItem('keyWord')).keyWord
            this.locationKeyWord = JSON.parse(localStorage.getItem('keyWord'))
        }


        if (localStorage.getItem('toPath')) {
            let s = JSON.parse(localStorage.getItem('toPath'))
            this.active = s.id
            switch (s.id) {
                case 0: {
                    this.downName = {id: 0, name: '全部分类'};
                    break
                }
                case 1: {
                    this.downName = {id: 1, name: '投标方案'};
                    break
                }
                case 2: {
                    this.downName = {id: 2, name: '合同范本'};
                    break
                }
                case 3: {
                    this.downName = {id: 3, name: '管理制度'};
                    break
                }
            }
        }

        if (localStorage.getItem('accessKey')) {
            getUserInfo().then(res => {
                localStorage.setItem('userInfo', res)
                localStorage.setItem('userInfo', res)
                let d = JSON.parse(decrypt(res))
                this.form = d
                this.headIcon = this.form.icon
            })
        }
        this.$bus.$on('headSet', res => {
            this.headIcon = res
        })
        this.$bus.$on('updateNickName', res => {
            this.nickName = res
        })


    },
    destroyed() {
        this.$bus.$off('loginSuccess')
        this.$bus.$off('readMsg')
        this.$bus.$off('chargeSuccess')
        this.$bus.$off('buySuccess')
        this.$bus.$off('headSet')
        this.$bus.$off('accountSuccess')
        this.$bus.$off('updateNickName')
        this.$bus.$off('PayDocSuccess')
    }

}
</script>

<style scoped lang="scss">
.ant-dropdown-menu::v-deep {
  /*background-color:rgba(0,0,0,0.5);*/
  width: 100px;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  margin-left: -20px;
}

.ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a::v-deep {
  display: block;
  margin: -5px -12px;
  padding: 10px 12px;
  color: #333;
  font-size: 14px;
  transition: all 0.3s;
}
</style>
