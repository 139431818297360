import service from "@/api/service";

export async function chargeList() {
    let res=await service({
        url:'/member/order/chargeList',
        method:'post'
    })

    return res.data
}
// 判断文档是否购买
export async function checkHaveBuy(id) {
    let res=await service({
        url:'/member/order/checkHaveBuy',
        method:'post',
        params:{bidId:id}
    })

    return res.data
}
// 使用标币购买标书
export async function buyBidByFund(data) {
    let res=await service({
        url:'/member/order/buyBidByFund',
        method:'post',
        data:data
    })

    return res.data
}
