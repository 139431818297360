<template>
    <div>
        <a-modal
                :footer="null"
                width="452px"
                :dialog-style="{ top: '150px',borderRadius:'30px',padding:0}"
                :visible="prompt"
                :maskClosable="false"
                @cancel="() => setModal1Visible(false)"
        >
          <div class="prompt" v-if="recharge">
              <div class="prompt_img">
                  <img src="../../assets/image/img/core.png" alt="">
                  <div>
                      标币充值成功！
                  </div>
              </div>
              <div class="prompt_btn">
                    <a-button @click="buyBid" type="primary">去购买方案</a-button>
              </div>
          </div>

            <div class="prompt" v-else>
                <div class="prompt_img">
                    <img src="../../assets/image/img/document_success.png" alt="">
                    <div>
                        文档购买完成 !
                    </div>
                    <div class="prompt_tip">
                        如果下载失败, 可重新下载此文档无需再次支付
                    </div>
                </div>
                <div class="prompt_doc_btn">
                    <a-button type="link" @click="setModal1Visible">我知道了</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import './pay_tips.scss'
    import {navigatePhoneUrl} from "@/utils/resToData";
    export default {
        props:['prompt','recharge'],
        name: "pay_prompt",
        methods:{
            setModal1Visible(){
                this.$emit('promptClose',true)
            },

            buyBid(){
                this.setModal1Visible()
            }
        }
    }
</script>

<style scoped>
    >>>.ant-modal-content {
        border-radius: 20px;
        padding: 0;
    }
    >>>.ant-modal-body {
        padding: 0;
    }
</style>