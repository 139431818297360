import services from "@/api/phone/https";
import service from "@/api/service";

export async function getListByCategory(data) {
    let res=await services({
        url:'/api/moduleTopic/getListByCategory',
        method:'post',
        data:data
    })

    return res.data
}
// 三大标签
export async function threeType() {
    let res=await services({
        url:'/api/moduleTopic/threeType',
        method:'post',
    })

    return res.data
}

export async function getCategoryTreePhone(data) {
    let res=await services({
        url:'/api/pub/getCategoryTree',
        method:'post',
        params:{typeId:data}
    })

    return res.data
}

// 文档分类列表
export async function queryCriteriaPhone(BidId) {
    let res=await service({
        url:'/api/moduleTopic/queryCriteria',
        method: 'post',
        params: {bidType :BidId}
    });

    return res.data
}


export async function getBidById(id) {
    let res=await services({
        url:'/api/moduleTopic/getBidById',
        method:'post',
        params:{id:id}
    })

    return res.data
}


export async function getListByModuleName(data) {
    let res=await services({
        url:'/api/moduleTopic/getListByModuleName',
        method:'post',
        params:data
    })

    return res.data
}

// 添加文档浏览记录
export async function addBidViewsPhone(bidId) {
    let res=await services({
        url:'/api/moduleTopic/addBidViews',
        method:'post',
        params: {bidId:bidId}
    })

    return res.data
}
// 添加用户浏览记录
export async function addViewsByUserPhone(bidId) {
    let res=await services({
        url:'/api/moduleTopic/addViewsByUser',
        method:'post',
        params: {bidId:bidId}
    })

    return res.data
}
// 使用标币购买标书
export async function buyBidByFundPhone(data) {
    let res=await services({
        url:'/api/order/buyBidByFund',
        method:'post',
        data:data
    })

    return res.data
}
// 添加文档购买记录
export async function addOmsBidLog(data) {
    let res=await services({
        url:'/api/order/addOmsBidLog',
        method:'post',
        data:data
    })

    return res.data
}
// 添加标币购买记录
export async function addFundChargeLog(data) {
    let res=await services({
        url:'/api/order/addFundChargeLog',
        method:'post',
        data:data
    })

    return res.data
}
// 标书定制
export async function createRequire(data) {
    let res=await services({
        url:'/api/center/createRequire',
        method:'post',
        data:data
    })

    return res.data
}

// 用户收藏
export async function addCollectLog(data) {
    let res=await services({
        url:'/api/moduleTopic/addCollectLog',
        method:'post',
        params:{bidId:data}
    })

    return res.data
}
// 取消收藏
export async function cancelCollectLog(data) {
    let res=await services({
        url:'/api/moduleTopic/cancelCollectLog',
        method:'post',
        params:{bidId:data}
    })

    return res.data
}
