<template>
    <div>
        <a-modal
                :footer="null"
                width="580px"
                :dialog-style="{ top: '150px',borderRadius:'30px',padding:0}"
                :visible="recharge"
                :maskClosable="false"
                @cancel="() => setModal1Visible(false)"
        >

            <div class="pay_home">
                <div class="pay_title">充值标币</div>
                <div class="pay_content">
                    <div class="pay_titles">
                        优惠充值
                        <div class="pay_tips_active">
                            <img src="../../assets/image/img/pay_tips.png" alt="">
                            <span>充值套餐，享套餐权益</span>
                        </div>
                    </div>
                    <div class="pay_money">
                        <div v-for="(item,index) in rechargeList"
                             :key="index"
                             @click="checkAmount(item)"
                             :class="active===item.id ? 'pay_actived_hello_word':'pay_active_hello_word'">
                            <div class="active_title">
                                <img src="../../assets/image/img/icon_pay_top.png" alt="">
                                <span>¥{{ item.price }} 套餐</span>
                            </div>
                            <div class="pay_content_money">
                                <div class="tip">标币</div>
                                <div class="money" :style="active===item.id ? {color:'#EF3A4A'} : {color: '#333'}">
                                    {{ item.total }}
                                </div>
                                <div class="delete">
                                    <del>¥{{ item.fund }}</del>
                                </div>
                                <div class="btn_payInfo">
                                    <div class="btn_zk">{{ ((item.price / item.total) * 10).toFixed(2) }}折优惠</div>
                                </div>
                            </div>
                            <div v-if="item.id === active" class="img_footer">
                                <img src="../../assets/image/img/icon_jb.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="create_qr">
                        <div class="create_title">扫码支付</div>
                        <div class="qr_body">
                            <div v-if="timeOver" class="qrCode" style="border: 1px solid #f6f6f6">
                                <a-button @click="restart" type="primary">
                                    <a-icon type="redo"/>
                                    重新获取
                                </a-button>
                            </div>
                            <div v-else class="qrCode">
                                <a-spin :spinning="loading">
                                    <div v-if="docToPay" id="qrCodeDocToImg" ref="qrCodeFDocImg"></div>
                                    <div v-else id="qrcodeCTRValue" ref="qrCodeImg"></div>
                                </a-spin>
                            </div>
                            <div class="qr_right">
                                <div class="code_money">¥ {{ amount }}</div>
                                <div class="qr_info">
                                    <div style="margin-left: 10px;margin-right: 15px;font-size: 18px">
                                        <!--                                        <a-icon type="wechat" theme="filled" style="color: #2cea0e;fontSize: 25px"/>-->
                                        <img src="@/assets/image/img/weixinzhifu.svg" style="width: 20px">
                                        微信支付
                                    </div>
                                    <div style="margin-left: 10px;margin-right: 15px;font-size: 18px">
                                        <img src="@/assets/image/img/zhifubaozhifu.svg" style="width: 20px">
                                        支付宝支付
                                    </div>
                                </div>
                                <div class="qr_time">
                                    <a-icon type="clock-circle"/>
                                    支付剩余时间：{{ timeSeconds }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>

        <pay-prompt :prompt="payPromptShow" :recharge="isRecharge" @promptClose="promptClose"></pay-prompt>
    </div>
</template>

<script>
import './pay.scss'
import {decrypt, encrypt} from "@/utils/AES";
import {chargeList} from "@/api/page/order";
import {debounce} from "@/utils/utils";
import QRCode from "qrcodejs2";
import phone_home from "@/pages/phone_home/phone_home";
import {createOrders} from "@/api/page/pay";
import {closeSocketWs, connectSocket, sendWSPush} from "@/utils/websocket";
import payPrompt from "@/views/page_pay/pay_prompt";
import {promoteBaiduInfo, updateOrderRecode} from "@/utils/promote";
import {addBehavior} from "@/data/accessKey";
import {mathRadomeString} from "@/utils/message";
import {getRandomCode} from "@/utils/RandomCode";

export default {
    props: ['recharge'],
    name: "pay",
    components: {phone_home, payPrompt},
    data() {
        return {
            active: 0,
            changeOrder: [],
            payOrder: [],
            rechargeList: [],
            amount: 0,
            time: 600,
            loading: true,
            timeClear: null,
            timeSeconds: 0,
            timeOver: false,
            payPromptShow: false,//购买成功后弹窗
            isRecharge: true, //是否为直接购买
            status: false,
            beginTime: Date.parse(new Date()),
            getSocketData: '',
            qrcode: '',
            docToPay: false
        }
    },
    methods: {

        // ##################################### 关闭订单 #####################################
        setModal1Visible() {
            this.payOrder = []
            this.docToPay = false
            if (this.changeOrder.length > 4) {
                const memberId = JSON.parse(decrypt(localStorage.getItem('userInfo')))
                addBehavior(
                    '订单多次被点击：',
                    `用户【${memberId.phone}】多次点击订单用户的IP为【${localStorage.getItem('locationIp') ? localStorage.getItem('locationIp') : '暂无ip'}】`,
                    location.href,
                    this.beginTime,
                    Date.parse(new Date()))
            }

            // this.$message.loading({content: '当前有订单未结束...', key: 'updatable'});

            setTimeout(() => {
                // this.$message.warning({content: '遇到未到账标币尝试刷新浏览器或联系客服!', key: 'updatable', duration: 2});
                this.$emit('payClose', true)
                addBehavior('PC订单支付：', '订单支付：' + '选择了【' + this.amount + '】选择了该金额，提前关闭弹窗，消息未推送！', location.href, this.beginTime, Date.parse(new Date()))
                window.clearInterval(this.timeClear)
                // closeSocketWs()
            }, 300);

        },


        // ##################################### 切换金额 #####################################
        checkAmount: debounce(function (item) {
            this.changeOrder.push(item)

            this.clear()

            this.amount = item.price

            this.active = item.id

            this.loading = true

            const obj = this.payOrder.find(value => value.id === item.id);
            if (obj) {
                let res = this.payOrder.filter(value => {
                    return value.id === item.id
                })
                if (this.docToPay) {
                    this.DocToPayQRCode(res[0].url)
                } else {
                    this.generateQRCode(res[0].url)
                }
            }

            if (this.payOrder.length <= 3) {
                this.createOrder({fund: item.total})
            }

            this.timeOut()


        }, 300, true),

        // ##################################### 倒计时 #####################################
        timeOut() {
            if (this.time > 0) {
                let b = 59
                let a = parseInt(this.time / 60) >= 10 ? parseInt(this.time / 60) - 1 : parseInt(this.time / 60);
                this.timeClear = setInterval(() => {
                    if (this.time > 1) {
                        this.time -= 1
                        if (b > 0) {
                            b -= 1
                        } else {
                            a -= 1
                            b = 59
                        }

                        this.timeSeconds = `0${a}:${b < 10 ? '0' + b : b}`
                    } else {
                        this.timeOver = true
                        window.clearInterval(this.timeClear)
                    }
                }, 1000)
            } else {
                this.timeOver = true
            }
        },

        // ##################################### 重新倒计时 #####################################
        restart() {
            this.time = 60
            this.timeOver = false
            this.timeOut()
        },

        // ##################################### 创建二维码 #####################################
        generateQRCode(userId) {
            // 即生成二维码之前先将存放二维码的标签清空
            // this.$refs.qrCodeUrlCtr=""

            let cabans = document.getElementById("qrcodeCTRValue")
            cabans.innerHTML = "";
            this.$refs.qrCodeImg.innerHTML = ""
            setTimeout(() => {
                this.loading = false
                this.qrcode = new QRCode(this.$refs.qrCodeImg, {
                    text: userId,
                    width: 150,
                    height: 150,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            }, 200)
        },

        DocToPayQRCode(userId) {
            // 即生成二维码之前先将存放二维码的标签清空
            // this.$refs.qrCodeUrlCtr=""
            let cabans = document.getElementById("qrCodeDocToImg")
            cabans.innerHTML = "";
            this.$refs.qrCodeFDocImg.innerHTML = ""

            setTimeout(() => {
                this.loading = false
                this.qrcode = new QRCode(this.$refs.qrCodeFDocImg, {
                    text: userId,
                    width: 150,
                    height: 150,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            }, 200)
        },
        // ##################################### 创建订单 #####################################
        createOrder(form) {
            if (this.payOrder.length > 0) {

                const obj = this.payOrder.find(item => item.id === this.active);

                if (obj) {
                    console.log('<>>>>>><><<<<<<<<<<<<<<<')
                } else {
                    connectSocket()

                    this.status = false

                    this.clear()

                    const memberId = JSON.parse(decrypt(localStorage.getItem('userInfo'))).id

                    let taxNum = 'BXZPC' + mathRadomeString() + Date.parse(new Date()).toString() + memberId

                    let order = {
                        type: 1, //默认1标币，2标书
                        payAmount: this.amount, //金额
                        serialNumber: taxNum,
                        note: form.fund,
                        promoteId: localStorage.getItem("promoted_id") ? localStorage.getItem("promoted_id") : '',
                        payUrl: localStorage.getItem("promoteUrl") ? localStorage.getItem("promoteUrl") : '',
                        code: getRandomCode()
                    }
                    createOrders(order).then(res => {
                        let s = encrypt(JSON.stringify({orderId: res}))
                        let url = `${process.env.VUE_APP_PAY_URL}?amount=${this.amount}&title='标币充值'&fund=${form.fund}&taxNum=${taxNum}&orderId=${res}&userId=${memberId}`
                        this.payOrder.push({id: this.active, url, orderId: s})
                        if (this.docToPay) {
                            this.DocToPayQRCode(url)
                        } else {
                            this.generateQRCode(url)
                        }
                        sendWSPush(s)
                        this.getSocketData = e => {
                            const data = e && e.detail.data
                            // 如果不是连接成功的心跳数据，弹出提示
                            if (data !== 'pong') {
                                this.handleNotifyMsg(data, e)  //处理消息的函数
                            }
                        }

                        // 注册监听事件
                        window.addEventListener('getMessage', this.getSocketData)
                        // 这里可以添加推广
                        updateOrderRecode(res, memberId)
                    }).catch(err => {
                    })
                }

            } else {

                connectSocket()

                this.status = false

                this.clear()

                const memberId = JSON.parse(decrypt(localStorage.getItem('userInfo'))).id

                let taxNum = 'BXZPC' + mathRadomeString() + Date.parse(new Date()).toString() + memberId
                let order = {
                    type: 1, //默认1标币，2标书
                    payAmount: this.amount, //金额
                    serialNumber: taxNum,
                    note: form.fund,
                    promoteId: localStorage.getItem("promoted_id") ? localStorage.getItem("promoted_id") : '',
                    payUrl: localStorage.getItem("promoteUrl") ? localStorage.getItem("promoteUrl") : '',
                    code: getRandomCode()
                }
                createOrders(order).then(res => {
                    let s = encrypt(JSON.stringify({orderId: res}))
                    let url = `${process.env.VUE_APP_PAY_URL}?amount=${this.amount}&title='标币充值'&fund=${form.fund}&taxNum=${taxNum}&orderId=${res}&userId=${memberId}`
                    this.payOrder.push({id: this.active, url})

                    if (this.docToPay) {
                        this.DocToPayQRCode(url)
                    } else {
                        this.generateQRCode(url)
                    }

                    sendWSPush(s)

                    this.getSocketData = e => {
                        const data = e && e.detail.data
                        // 如果不是连接成功的心跳数据，弹出提示
                        if (data !== 'pong') {
                            this.handleNotifyMsg(data, e)  //处理消息的函数
                        }
                    }

                    // 注册监听事件
                    window.addEventListener('getMessage', this.getSocketData)
                    // 这里可以添加推广
                    updateOrderRecode(res, memberId)
                }).catch(err => {
                })
            }
        },

        // ##################################### 返回值 #####################################
        handleNotifyMsg(data, e) {
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', 'ok', '>>>>>>>>>>>>>>>>>>>>>>>>>>>')
            let reg = RegExp('success')
            if (reg.test(data)) {
                let s = sessionStorage.getItem('docOrder')
                if (s) {

                } else {
                    this.paySuccess()
                }

            }
        },

        // ##################################### 支付成功 #####################################
        paySuccess() {
            this.$emit('payClose', true)
            if (this.payPromptShow) {
            } else {
                this.payPromptShow = true;
            }
            this.$bus.$emit('chargeSuccess', true)
            promoteBaiduInfo(10)
            setTimeout(() => {
                this.promptClose()
                this.clear()
            }, 3000)
        },

        // ##################################### 提示框关闭 #####################################
        promptClose() {
            this.payPromptShow = false
        },

        // 时间初始化
        // ##################################### 时间初始化 #####################################
        clear() {
            this.time = 600
            window.clearInterval(this.timeClear)

        },

    },


    mounted() {
        let s = localStorage.getItem('userInfo')
        if (s) {
            let userInfo = JSON.parse(decrypt(localStorage.getItem('userInfo')))
            this.userInfo = userInfo
        }
        chargeList().then(res => {
            this.rechargeList = res
        })
        this.$bus.$on('docToPay', data => {
            this.docToPay = true
        })
    },


    watch: {
        recharge(newValue, oldValue) {
            if (newValue) {

                this.changeOrder = []
                this.payOrder = []
                this.qrcode = ''
                this.rechargeList.forEach(((value, index) => {
                    if (index === 0) {
                        this.active = value.id
                        this.amount = value.price.toFixed(2)
                        this.loading = true
                        this.createOrder({fund: value.total})
                        this.timeOut()
                    }
                }))
            }
        }
    }
}
</script>

<style scoped>
>>> .ant-modal-content {
    border-radius: 20px;
    padding: 0;
}

>>> .ant-modal-body {
    padding: 0;
}
</style>
