// 验证手机号码
export function phoneNumCheck(str){
    let reg = /^1[3456789]\d{9}$/;
    // ^1  以1开头
    // [3456789] 第2位，使用原子表里的任意一个原子都可以
    // \d{9}$  第三位  朝后可以是任意数字  并且最后结尾必须是数字
    if(reg.test(str)){
        return true;
    }else{
        return false;
    }
}

export function advertiseSetting(arr) {
    let array=[]

    arr.forEach(value=>{
       if (value.isTemp===1){
            array.push({imgUrl:value.imgUrl, place:value.place, status:1, id:value.id, close:value.isCancel===1 ? true:false, toLeft:value.toLeft, toRight:value.toRight, height:value.height, width:value.width, top:value.top,})
       }else{
           if (value.beginTime < Date.parse(new Date()) && value.endTime > Date.parse(new Date()) ){
               array.push({imgUrl:value.imgUrl, place:value.place, status:1, id:value.id, close:value.isCancel===1 ? true:false, toLeft:value.toLeft, toRight:value.toRight, height:value.height, width:value.width, top:value.top,
               })
           }else{
               array.push({imgUrl:value.imgUrl, place:value.place, status:0, id:value.id, close:value.isCancel===1 ? true:false, toLeft:value.toLeft, toRight:value.toRight, height:value.height, width:value.width, top:value.top,})
           }
       }
    })

    return array
}
