import service from "@/api/service";

import {client} from "@/utils/ali_oss";

// 标书类别
export async function getDataDictionary() {
    let res=await service({
        url:'/member/pub/getDataDictionary',
        method: 'post',
        data:{}
    });

    return res.data
}

// 上传文件
export async function  uploadDocument(file,fileName) {

    let res=  client.put(
        'customerDemand/' + fileName,
        file
    )

    return res
}

// 上传文件
// 上传图片
export async function  uploadImageFile(file) {
    // 阿里云OSS上传
    // 判断扩展名
    console.log(file.file)
    const tmpcnt = file.name.lastIndexOf('.') // 获取.的下标
    const uploadFileName = file.name.substring(0, tmpcnt) // 获取文件名
    const exname = file.name.substring(tmpcnt + 1) // 获取后缀名
    const names = ['jpg', 'jpeg', 'png', 'webp']
    if (names.indexOf(exname) < 0) {
        return false
    }

    const fileName = uploadFileName + '.' + exname
    let res= await client.put(
        'image/' + fileName,
        file
    )

    return res
}

export async function pcAddAccessLog(data) {
    let res = await service({
        url:'/member/pub/addAccessLog/',
        method:'post',
        data: data
    })
    return res.data
}

// 获取广告列表
export async function getAdList() {

   let res =await service({
       url:'/member/pub/getAdList',
       method:'post',
   })

    return res.data
}
// 判断用户行为
export async function addUserBehaviorLog(data) {

   let res =await service({
       url:'/member/pub/addUserBehaviorLog',
       method:'post',
       data:data
   })

    return res.data
}
// 三大标签
export async function getThreeLabel(data) {

   let res =await service({
       url:'/member/pub/getThreeLabel',
       method:'post',
       params: {id:data}
   })

    return res.data
}
export async function getThreeLabel2(id) {

   let res =await service({
       url:'/member/pub/getThreeLabel2',
       method:'post',
       params: {id:id}
   })

    return res.data
}
export async function getThreeLabel3(id) {

   let res =await service({
       url:'/member/pub/getThreeLabel3',
       method:'post',
       params: {id:id}
   })

    return res.data
}
export async function getThreeLabel4(id) {

    let res =await service({
        url:'/member/pub/getThreeLabel4',
        method:'post',
        params: {bidId:id}
    })

    return res.data
}

// 协议查询
export async function  getAgreement() {

   let res =await service({
       url:'/member/moduleTopic/getAgreement',
       method:'post',
   })

    return res.data
}



