import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
Vue.config.productionTip = false
Vue.use(Antd);

import clientWidthLogin from './utils/clientWidth'
Vue.use(clientWidthLogin)

import accessDict from "@/data/accessKey";
Vue.use(accessDict)

import promoted from "@/utils/promote";
Vue.use(promoted)

import accessKey from "@/api/phone/promoteKey";
Vue.use(accessKey)

import accessKeyEnterPhone from "@/api/phone/accessKeyPhone";
Vue.use(accessKeyEnterPhone)

import GetPromoteAddress from "@/data/getPromoted";
Vue.use(GetPromoteAddress)

import {getRandomCode} from "@/utils/RandomCode";
Vue.use(getRandomCode)

import router from './router'
import store from './store'


// // 引入antd vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

// 引入vant组件
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);


import './utils/lib-flexible'
new Vue({
  router,
  store,
  beforeCreate() {
    // bus 总线
    // $ 为了迎合 vue 的命名习惯
    // 安装全局事件总线，$bus就是当前应用的vm
    Vue.prototype.$bus = this
  },
  render: h => h(App)
}).$mount('#app')
