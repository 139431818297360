<template>
    <div>
        <!--        广告-->
        <div v-show="isLeft" class="advertise">
            <img style="min-width: 100px;" @click="custom(leftList)" :src="leftList.imgUrl" alt="">
<!--            <div style="margin-top: 10px" class="adv_close">-->
<!--                <a-button size="small" @click="closed(0)" shape="circle" icon="close" />-->
<!--            </div>-->
        </div>

        <div v-show="isRight" class="advertise_right">
            <img  @click="customRight(rightList)" :src="rightList.imgUrl" alt="">

            <div style="margin-top: 10px" class="adv_close">
                <a-button  size="small" @click="closed(1)" shape="circle" icon="close" />
            </div>
        </div>

    </div>

</template>

<script>
    import {getAdList} from "@/api/page/public";
    import {navigateUrl} from "@/utils/resToData";

    export default {
        name: "advertise",
        props:['bannerList','isLeft','isRight'],
        data(){
          return{
              leftList:{},
              rightList:{}
          }
        },

        methods:{
            custom(e){
                if (e.url){
                    sessionStorage.setItem('activeId',e.id)
                    window.open(e.url, '_blank');
                }else{

                }

            },

            customRight(e){
                if (e.url){
                    sessionStorage.setItem('activeId',e.id)
                    window.open(e.url, '_blank');
                }else{

                }
            },

            closed(e){
               this.$emit('btnClose', {data:e})
            }
        },

        watch:{
            bannerList(newValue,oldValue){
                this.bannerList.forEach(value=>{
                    if (value.place===8){
                        this.leftList=value
                    }
                    if (value.place===9){
                        this.rightList=value
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .advertise{
        min-width: 200px;
        min-height: 100px;
        position: fixed;
        top: 500px;
        left: 1%;
        z-index: 999;
        img{
            min-width: 200px;
            border-radius: 10px;
            min-height:170px;
        }
        .adv_close{

            height: 20px;

            display: flex;
            justify-content: center;
            button{
                background: none;
            }
        }
    }
    .advertise_right{
        min-width: 200px;
        min-height: 100px;
        position: fixed;
        top: 450px;
        right: 10px;
        z-index: 999;
        img{
            width: 200px;
            border-radius: 10px;
            min-height: 170px;
        }
        .adv_close{
            width: 100%;
            height: 40px;
            position: absolute;
            bottom: -50px;
            display: flex;
            justify-content: center;
            button{
                background: none;
            }
        }
    }

</style>
