import Cookies from 'js-cookie';
import {pcAddAccessLog} from "@/api/page/public";
import {phoneAddAccessLog} from "@/api/phone/public";
import {parseTime} from "@/utils/parse";

export function getRandomCode() {
    let userIdentifier = Cookies.get('user_identifier');
    if (userIdentifier) {
        userIdentifier = JSON.parse(userIdentifier);
        if (calibrationTime(userIdentifier.time)) {
            return userIdentifier.code;
        }
    }
    const newUserIdentifier = getUserIdentifier();
    Cookies.set('user_identifier', JSON.stringify(newUserIdentifier));
    return newUserIdentifier.code;
}

function getUserIdentifier() {
    let userIdentifier = {
        code: generateRandomCode(),
        // accessUrl: window.location.href,
        accessUrl: window.location.href.includes('/#/&bd_vid=') ? window.location.href.replace(/(\/#\/&bd_vid=)/, '/#/?bd_vid=') : window.location.href,
        time: new Date()
    }
    addAccessLog(userIdentifier)
    return userIdentifier;
}

function addAccessLog(userIdentifier) {
    if (window.location.href.includes('phone')) {
        phoneAddAccessLog(userIdentifier).then()
    } else {
        pcAddAccessLog(userIdentifier).then()
    }
}

function generateRandomCode() {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let randomString = '';
    for (let i = 0; i < 15; i++) {
        let index = Math.floor(Math.random() * chars.length);
        randomString += chars.charAt(index);
    }
    return randomString;
}

function calibrationTime(time) {
    const currentTime = new Date().getTime();
    const inputTime = new Date(time).getTime();

    const diffHours = Math.abs((currentTime - inputTime) / (1000 * 60 * 60));
    return diffHours <= 6;
}