<template>
    <div>
<!--        <nav_phone :login="true"></nav_phone>-->
        <router-view/>
        <tabbar></tabbar>
    </div>
</template>

<script>
    import nav_phone from "../components/phone/nav_phone";
    import tabbar from "@/pages/phone_tabbar/tabbar";
    export default {
        name: "App",
        components:{nav_phone,tabbar},
    }
</script>

<style scoped>

</style>
