//lib-flexible.js
(function (doc, win) {
    var docEl = doc.documentElement,
        w = 375, //设计稿实际宽度
        resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
        recalc = function () {
            //控制基础字号
            var clientWidth = docEl.clientWidth; //取到宽度
            if (!clientWidth) return;

            // 设计稿宽度是640 所以需要控制不能超过640
            // if (clientWidth >= w) {
            //   clientWidth = w;
            // }

            docEl.style.fontSize = 100 * (clientWidth / w) + "px";
        };

    //窗口变化时，文档加载完毕时，控制基础字号
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener("DOMContentLoaded", recalc, false);
})(document, window);
