<template>
    <div>
        <a-modal
                :footer="null"
                width="580px"
                :dialog-style="{ top: '150px',borderRadius:'30px',padding:0}"
                :visible="qrDownload"
                :maskClosable="false"
                @cancel="() => setModal1Visible(false)"
        >
            <div class="qr_home">
                <div class="qr_title">扫码支付下载此文档</div>
                <div class="qr_content_main">
                    <div class="qr_left">
                        <div class="money">支付金额 <b>{{document.price - userInfo.fund}}元</b></div>
                        <div class="qrCode">
                            <a-spin :spinning="loading" >
                                <div  id="qrcodeDocValue" ref="qrCodeUrlDocValue"></div>
                            </a-spin>
                        </div>
                        <div class="money"><span>使用手机扫码支付</span></div>
                    </div>
                    <div style="height: 200px;width: 10px;padding: 20px 0;box-sizing: border-box">
                        <a-divider style="height: 100%" type="vertical" />
                    </div>
                    <div class="qr_right">
                        <div class="qr_tips">您当前已有标币 <b>{{userInfo.fund}}</b> , 还需购买 <b>{{document.price - userInfo.fund}}</b> 标币</div>
                        <div class="qr_titles">标题：{{document.title}}</div>
                        <div class="qr_titles">格式：doc/docx</div>
                        <div class="qr_titles">页码：{{document.pages}}页</div>
                        <div class="qr_titles" @click="toRecharge">
                            <a-button type="primary">充值标币更优惠</a-button>
                        </div>
                    </div>
                </div>
            </div>

        </a-modal>

        <pay :recharge="recharge" @payClose="payClose"></pay>

        <pay-prompt :prompt="payPromptShow" :recharge="isRecharge" @promptClose="payPromptShow=false"></pay-prompt>
    </div>
</template>

<script>
    import './pay_doc.scss'
    import {decrypt, encrypt} from "@/utils/AES";
    import pay from "@/views/page_pay/pay";
    import {closeSocketWs, connectSocket, sendWSPush} from "@/utils/websocket";
    import {createOrders} from "@/api/page/pay";
    import QRCode from "qrcodejs2";
    import payPrompt from "@/views/page_pay/pay_prompt";
    import {promoteBaiduInfo, updateOrderRecode} from "@/utils/promote";
    import {buyBidByFund} from "@/api/page/order";
    import {addDownLoadLog} from "@/api/page/promoted";
    import {addBehavior} from "@/data/accessKey";
    import {getRandomCode} from "@/utils/RandomCode";
    export default {
        props:['qrDownload','payContent'],
        components:{pay,payPrompt},
        name: "pay_doc",
        data(){
            return{
                document:{},
                userInfo:{},
                loading:true,
                recharge:false,
                payPromptShow:false,
                isRecharge:false,
                beginTime:Date.parse(new Date())
            }
        },
        methods:{

            payClose(){
                this.recharge=false
            },

            setModal1Visible(e){
                setTimeout(() => {
                    // this.$message.warning({ content: '遇到未到账标币尝试刷新浏览器或联系客服!', key:'updatable', duration: 2 });
                    this.$emit('qrClose',true)
                    addBehavior('直接购买文档','购买'+this.document.title+'文档，金额支付,未支付提前关闭'+this.document.price,location.href,this.beginTime,Date.parse(new Date()))
                    window.clearInterval(this.timeClear)
                    sessionStorage.removeItem('docOrder')
                }, 500);
            },

            toRecharge(){
                // closeSocketWs()
                sessionStorage.removeItem('docOrder')
                this.setModal1Visible()
                setTimeout(()=>{
                  this.$message.info({ content: '已切换到充值.'});
                  this.$bus.$emit('docToPay',true)
                  this.recharge=true
                },500)


            },


            // ##################################### 创建订单 #####################################
            createOrder(form){
                // closeSocketWs()

                connectSocket()

                const member=JSON.parse(decrypt(localStorage.getItem('userInfo')))

                let taxNum='BXZPC'+ Date.parse(new Date()).toString() + member.id + parseInt(Math.random()*1000)

                let order={
                    type:2, //默认1标币，2标书
                    payAmount:this.document.price - member.fund, //金额
                    serialNumber:taxNum,
                    note:this.document.price - member.fund,
                    promoteId:localStorage.getItem("promoted_id") ? localStorage.getItem("promoted_id"):'',
                    payUrl: localStorage.getItem("promoteUrl") ? localStorage.getItem("promoteUrl") : '',
                    code: getRandomCode()
                }

                createOrders(order).then(res=>{

                    let s=encrypt(JSON.stringify({orderId:res}))

                    let url=`${process.env.VUE_APP_PAY_URL}?amount=${this.document.price - member.fund}&title='标币充值'&fund=${this.document.price - member.fund}&taxNum=${taxNum}&orderId=${res}&userId=${member.id}`

                    this.generateQRCode(url)

                    sessionStorage.setItem('docOrder',order.serialNumber)

                    sendWSPush(s)

                    this.getSocketData = e => {
                        const data = e && e.detail.data
                        // 如果不是连接成功的心跳数据，弹出提示
                        if (data !== 'pong') {
                            this.handleNotifyMsgDoc(data,e)  //处理消息的函数
                        }
                    }

                    // 注册监听事件
                    window.addEventListener('getMessage', this.getSocketData)
                    // 这里可以添加推广
                    updateOrderRecode(res,member.id)
                }).catch(err=>{})

            },

            // ##################################### 创建二维码 #####################################
            generateQRCode(userId) {
                // 即生成二维码之前先将存放二维码的标签清空
                document.getElementById("qrcodeDocValue").innerHTML = "";
                let qrcode = new QRCode(this.$refs.qrCodeUrlDocValue, {
                    text: userId,
                    width: 150,
                    height: 150,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
                this.loading= false
            },

            // ##################################### 返回值 #####################################
            handleNotifyMsgDoc(data,e){
                console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>','ok','>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                let reg=RegExp('success')
                if (reg.test(data)){
                    let orders = sessionStorage.getItem('docOrder')
                    if (orders){
                        this.paySuccess()
                    }
                }
            },
            // ##################################### 支付成功 #####################################
            paySuccess(){

                let userInfo = JSON.parse(decrypt(localStorage.getItem('userInfo')))
                let getBuy={memberId :userInfo.id,memberPhone:userInfo.phone,bidId:this.document.id,bidName:this.document.title,amount:this.document.price}

                buyBidByFund(getBuy).then(res=>{
                    this.$emit('qrClose',true)
                    window.clearInterval(this.timeClear)
                    sessionStorage.removeItem('docOrder')
                    // closeSocketWs()
                    this.payPromptShow=true
                    this.$bus.$emit('PayDocSuccess',true)
                    let elemIF = document.createElement("iframe");
                    elemIF.src = this.document.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                    addDownLoadLog(this.document.id).then(res=>{})
                    promoteBaiduInfo(10)
                }).catch(err=>{})

            }

        },

        watch :{
            qrDownload(newValue,oldValue){
                if (newValue){
                    let userInfo = JSON.parse(decrypt( localStorage.getItem('userInfo')))
                    this.userInfo=userInfo
                    this.document= this.payContent
                    this.createOrder(this.document)
                }
            }
        }
    }
</script>

<style scoped>
    >>>.ant-modal-content {
        border-radius: 20px;
        padding: 0;
    }
    >>>.ant-modal-body {
        padding: 0;
    }
</style>
