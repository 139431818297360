import service from "@/api/service";

export async function addAccessLog(data) {
    let res=await service({
        url:'/member/promoted/addAccessLog',
        method:'post',
        params: {info:data}
    })

    return res.data
}
// 添加订单关系
export async function addOrderRelation(data) {
    let res=await service({
        url:'/member/promoted/addOrderRelation',
        method:'post',
        data:data
    })

    return res.data
}
// 添加注册记录
export async function addRegisterLog(data) {
    let res=await service({
        url:'/member/promoted/addRegisterLog',
        method:'post',
        data:data
    })

    return res.data
}
// 根据code查看推广信息
export async function getPromotionByCode(data) {
    let res=await service({
        url:'/member/promoted/getPromotionByCode',
        method:'post',
        params: {code:data}
    })

    return res.data
}
// 更新用户注册记录
export async function updateRegisterLog(data) {
    let res=await service({
        url:'/member/promoted/updateRegisterLog',
        method:'post',
        data:data
    })

    return res.data
}

// 添加未登录的浏览记录
export async function readDocNoLogin(data) {
    let res = await service({
        url: '/moduleTopic/addViewCount',
        method: 'post',
        params: {bidId:data}
    })
    return res.data
}

// 添加下载记录
// 添加下载记录
export async function addDownLoadLog(value) {
    let res = await service({
        url:'/member/moduleTopic/addDownLoadLog',
        method: 'post',
        params:{bidId:value}
    })
    return res.data
}

// 添加未登录的浏览记录
export async function promoteToBaidu(data) {
    let s={logidUrl:data.url,newType:data.type}
    let a = [];
    a.push(s)
    let res = await service({
        url: '/member/promoted/sendConvertData',
        method: 'post',
        data: a
    })
    return res.data
}
export async function promoteTo360(value) {
    let res = await service({
        url:'/member/promoted/send360ConvertData',
        method: 'post',
        params:{url:value}
    })
    return res.data
}


