import service from "@/api/service";

// 获取二维码
export async function getAccessToken() {
    let url=Date.now().toString()+parseInt(Math.random()*1000*10000).toString()

    let res=await service({
        url:'/member/officalAccount/getQcCode',
        method: 'get',
        params:{sceneStr:url}
    });

    return res.data
}
// 判断是否已经扫码
export async function getAccessTicket(e) {
    let res=await service({
        url:'/member/officalAccount/getQcCodeResult',
        method: 'get',
        params:{ticket:e}
    });

    return res.data
}

