import service from "@/api/service";

export async function  myStatistics() {
    let res = await service({
        url:'/member/center/myStatistics',
        method:'post',
    })

    return  res.data
}
// 充值记录
export async function  chargeLogList() {
    let res = await service({
        url:'/member/center/chargeLogList',
        method:'post',
    })

    return  res.data
}
// 购买记录
export async function  orderList() {
    let res = await service({
        url:'/member/center/orderList',
        method:'post',
    })

    return  res.data
}
// 开票记录
export async function  invoiceApplicationLog() {
    let res = await service({
        url:'/member/center/invoiceApplicationLog',
        method:'post',
    })

    return  res.data
}
// 查看未开发票详情
export async function  invoiceableOrders() {
    let res = await service({
        url:'/member/center/invoiceableOrders',
        method:'post',
    })

    return  res.data
}
// 开票申请
export async function  createApplication(data) {
    let res = await service({
        url:'/member/center/createApplication',
        method:'post',
        data:data
    })

    return  res.data
}
// 用户消息
export async function  messageList(data) {
    let res = await service({
        url:'/member/center/messageList',
        method:'post',
        params:data
    })

    return  res.data
}
// 用户消息
export async function  editMessage(data) {
    let res = await service({
        url:'/member/center/editMessage',
        method:'post',
        data:data
    })

    return  res.data
}
// 标书定制
export async function  createRequire(data) {
    let res = await service({
        url:'/member/center/createRequire',
        method:'post',
        data:data
    })

    return  res.data
}
// 批量阅读
export async function  bulkRead() {
    let res = await service({
        url:'/member/center/bulkRead',
        method:'post',
    })

    return  res.data
}
// 用户带阅读消息
export async function  unreadCount() {
    let res = await service({
        url:'/member/center/unreadCount',
        method:'post',
    })

    return  res.data
}
// 收藏列表
export async function  collectList() {
    let res = await service({
        url:'/member/center/collectList',
        method:'post',
    })

    return  res.data
}
