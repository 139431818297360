
import {decrypt, encrypt} from "@/utils/AES";
import axios from "axios";
import {addAccessLog, addRegisterLog, getPromotionByCode, updateRegisterLog} from "@/api/page/promoted";

import {getCaption} from "@/utils/parse";
import {removeEmptyField} from "@/utils/resToData";
import {checkExistByPhone, getUserInfo} from "@/api/phone/public";
import {promoteBaiduInfo} from "@/utils/promote";


export default function accessKeyEnterPhone () {

    let reg_t0=/[?]v1/g //百度
    let reg_t1=/[?]v2/g //360
    let reg_t2=/[?]v3/g //知乎
    let reg_t3=/[?]v4/g //搜狗
    let reg_t4=/[?]v5/g //微信公众号
    let reg_t5=/[?]v6/g //今日头条
    let reg_t6=/[?]v7/g //小红书


    getUserIP().then(res=>{localStorage.setItem('locationIp',res.data.ip)}).catch(err=>{})


    if(reg_t0.test(location.href)){
        localStorage.setItem('instatus',1) //baidu
        return '1'
    }else if (reg_t1.test(location.href)){
        localStorage.setItem('instatus',2) //360
        return '2'
    }else if (reg_t2.test(location.href)){
        localStorage.setItem('instatus',3)//知乎
        return '3'
    }else if (reg_t3.test(location.href)){//搜狗
        localStorage.setItem('instatus',4)
        return '4'
    }else if (reg_t4.test(location.href)){
        localStorage.setItem('instatus',5) //微信公众号
        return '5'
    }else if (reg_t5.test(location.href)){
        localStorage.setItem('instatus',6) //今日头条
        return '6'
    }else if (reg_t6.test(location.href)){
        localStorage.setItem('instatus',7) //小红书
        return '7'
    }
    else{
        localStorage.setItem('instatus',10) //自己注册
        return '10'
    }

}

// 请求数据上传数据
export function sendPostRegPhone (registerPlatform,status) {

    let form={userId:null,isPromotion:null,source:null,registerPlatform:registerPlatform ? registerPlatform:2,ip:'',promoteId:null,promotionKeyword:null}
    console.log('<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>欢迎来到标小助>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    getUserInfo().then(response=>{
        let isPromotion=''
        isPromotion=accessKeyEnterPhone()
        if (isPromotion==='1'){
            // 百度
            form.source=1
        }else if (isPromotion==='2'){
            // 360
            form.source=2
        }else if (isPromotion==='3'){
            // 知乎
            form.source=3
        }else if (isPromotion==='4'){
            // 搜狗
            form.source=4
        }else if (isPromotion==='5'){
            // 微信公众号
            form.source=5
        }else if (isPromotion==='6'){
            // 今日头条
            form.source=6
        }else if (isPromotion==='7'){
            // M_THE_LITTLE_RED_BOOK
            // 小红书
            form.source=7
        }
        else if (isPromotion==='10'){
            // 自行注册
            form.source=0
        }else{
            form.source=11 //非法途径
        }
        if (isPromotion==='10'){form.isPromotion=0}else {form.isPromotion=1}

        form.userId=JSON.parse(decrypt(response)).id

        if (status){
            promoteBaiduInfo(49)
            updatePromotedLogin(form.userId,form.source,localStorage.getItem('locationIp') ? localStorage.getItem('locationIp') : '',form.isPromotion)
        }else{
            promoteBaiduInfo(3)
            updatePromoted(form.userId,form.source,localStorage.getItem('locationIp') ? localStorage.getItem('locationIp') : '',form.isPromotion)
        }
    }).catch(err=>{
        console.log(err)
    })

}

export async function  getUserIP(){
    let s= await axios.get('https://api.ipify.org?format=json')
    return  s
}

// 检测统计数据(注册)
export function updatePromoted (userId,source,ip,isPromotion) {
    let reg=/[?]v[0-9]/g
    if (reg.test(location.href)){
        let s = getCaption(location.href, 'cn/?v')
        let arr = s.slice(6).split('/')[0]

        getPromotionByCode(arr).then(res=>{
            if (res){
                let promoted =decrypt(res)
                let p=getCaption(promoted.slice(12,-1).split(',')[0],'=')
                let k=getCaption(promoted.slice(12,-1).split(',')[5],'=')

                let form={promoteId:parseInt(p),promotionKeyword:k,userId:userId,source:source,ip:ip,isPromotion:isPromotion,registerPlatform:1,accessUrl:localStorage.getItem('promoteUrlPhone')}

                let info=`${form.promoteId ? form.promoteId : 9999999999},${ip},${form.registerPlatform}`
                addAccessLog(encrypt(info)).then(()=>{})

                addRegisterLog(removeEmptyField(form)).then(res=>{if (res !==null){localStorage.setItem('promoteForm',JSON.stringify( {id:res}))}})

            }else{
                let form={promoteId:'',promotionKeyword:'',userId:userId,source:source,ip:ip,isPromotion:isPromotion,registerPlatform:1,accessUrl:localStorage.getItem('promoteUrlPhone')}

                addRegisterLog(removeEmptyField(form)).then(res=>{
                    if (res !==null){localStorage.setItem('promoteForm',JSON.stringify( {id:res}))}
                })
            }
        }).catch(er=>{
            console.log(er)
            let form={promoteId:'',promotionKeyword:'',userId:userId,source:source,ip:ip,isPromotion:isPromotion,registerPlatform:1,accessUrl:localStorage.getItem('promoteUrlPhone')}
            addRegisterLog(removeEmptyField(form)).then(res=>{
                if (res !==null){localStorage.setItem('promoteForm',JSON.stringify( {id:res}))}}).catch(err=>{})
        })
    }else{
        let form={promoteId:'',promotionKeyword:'',userId:userId,source:source,ip:ip,isPromotion:isPromotion,registerPlatform:1,accessUrl:localStorage.getItem('promoteUrlPhone')}
        addRegisterLog(removeEmptyField(form)).then(res=>{
            if (res !==null){localStorage.setItem('promoteForm',JSON.stringify( {id:res}))}}).catch(err=>{})
    }
}

// 检测统计数据(登录)
export function updatePromotedLogin(userId,source,ip,isPromotion) {
    let reg=/[?]v[0-9]/g
    if (reg.test(location.href)){
        let s = getCaption(location.href, 'cn/?v')
        let arr = s.slice(6).split('/')[0]

        getPromotionByCode(arr).then(res=>{
            if (res){
                let promoted =decrypt(res)
                let p=getCaption(promoted.slice(12,-1).split(',')[0],'=')
                let k=getCaption(promoted.slice(12,-1).split(',')[5],'=')

                let form={promoteId:parseInt(p),promotionKeyword:k,userId:userId,source:source,ip:ip,isPromotion:isPromotion,registerPlatform:2,accessUrl:localStorage.getItem('promoteUrlPhone')}

                let info=`${form.promoteId ? form.promoteId : 9999999999},${ip},${form.registerPlatform}`
                addAccessLog(encrypt(info)).then(()=>{})

                // addRegisterLog(removeEmptyField(form)).then(res=>{if (res !==null){localStorage.setItem('promoteForm',JSON.stringify( {id:res}))}})

            }else{
                // let form={promoteId:'',promotionKeyword:'',userId:userId,source:source,ip:ip,isPromotion:isPromotion,registerPlatform:1,accessUrl:localStorage.getItem('promoteUrlPhone')}
                // let info=`${form.promoteId},${ip},${form.registerPlatform}`
                // addAccessLog(encrypt(info)).then(()=>{})
                // addRegisterLog(removeEmptyField(form)).then(res=>{
                //     if (res !==null){localStorage.setItem('promoteForm',JSON.stringify( {id:res}))}
                // })
            }
        }).catch(er=>{
            let form={promoteId:'',promotionKeyword:'',userId:userId,source:source,ip:ip,isPromotion:isPromotion,registerPlatform:2,accessUrl:localStorage.getItem('promoteUrlPhone')}
            let info=`${form.promoteId ? form.promoteId : 9999999999},${ip},${form.registerPlatform}`
            addAccessLog(encrypt(info)).then(()=>{})
        })
    }else{
        let form={promoteId:'',promotionKeyword:'',userId:userId,source:source,ip:ip,isPromotion:isPromotion,registerPlatform:2,accessUrl:localStorage.getItem('promoteUrlPhone')}
        let info=`${form.promoteId ? form.promoteId : 9999999999},${ip},${form.registerPlatform}`
        addAccessLog(encrypt(info)).then(()=>{})
    }
}

// 站内搜索
export function searchKeyPhone(keyword) {
    if (localStorage.getItem('promoteForm')){
        let forms=JSON.parse(localStorage.getItem('promoteForm'))
        forms.id=parseInt(forms.id)
        forms.searchWord=keyword
        updateRegisterLog(removeEmptyField(forms)).then(r => {localStorage.removeItem('promoteForm')}).catch(err=>{})
    }
}


