<template>
    <div>
        <div class="phone_top">
            <img src="../../assets/image/h5/logo.png" alt="">
            <div class="phone_search">
                <div class="phones_search">
                    <img src="../../assets/image/h5/search.png" alt="">
                    <input v-model="searchValue" type="text" placeholder="请输入关键词">
                    <button @click="searchIpt">搜索</button>
                </div>
            </div>
        </div>

        <div class="phone_banner">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item,index) in bannerList" :key="index" @click="bannerUrl(item)">
                    <img class="phone_image" :src="item.imgUrl" alt="">
                </van-swipe-item>
            </van-swipe>
        </div>

        <div class="phone_custom_ctv012">
            <div class="ctvartter_01" @click="information(1)">
                <div class="crytter_top">
                    <img src="../../assets/image/h5/1.png" />
                </div>
                <div class="chdask_text">投标方案</div>
            </div>
            <div class="ctvartter_01" @click="information(2)">
                <div class="crytter_top">
                    <img  src="../../assets/image/h5/2.png" />
                </div>
                <div class="chdask_text">合同范本</div>
            </div>
            <div class="ctvartter_01" @click="information(3)">
                <div class="crytter_top">
                    <img  src="../../assets/image/h5/3.png" />
                </div>
                <div class="chdask_text">管理制度</div>
            </div>
        </div>



        <div class="phone_content">
            <div class="phone_title">
                <span @click="categoryType=1" :class="categoryType===1 ? 'actived':'active' ">投标方案</span>
                <span @click="categoryType=2" :class="categoryType===2 ? 'actived':'active' ">合同范本</span>
                <span @click="categoryType=3" :class="categoryType===3 ? 'actived':'active' ">管理制度</span>
            </div>
        </div>

        <div class="phone_cards">
            <van-sticky style="height: 0px;text-align: right;" z-index="0" :offset-top="top">
                <div style="padding: 0 0.2rem 0 0;box-sizing: border-box">
                    <img @click="chat" src="../../assets/image/h5/chat.png" alt="">
                </div>
            </van-sticky>

            <card :search-form="form"></card>
        </div>

    </div>
</template>

<script>
    import './phone_home.scss'
    import card from "@/components/phone/card";
    import {GetBanner} from "@/api/phone/public";
    import {accessGetBanner, addBehavior} from "@/data/accessKey";
    import {navigatePhoneUrl} from "@/utils/resToData";
    import {searchKey} from "@/api/phone/promoteKey";
    export default {
        name: "phone_home",
        components:{card},
        data(){
          return{
              name:0,
              categoryType:1,
              searchValue:'',
              form:{
                  pageNum:1,
                  pageSize:10,
                  bidType:0,
                  categoryId:'',
                  keyWord:'',
                  maxPage:'',
                  minPage:'',
                  type:1
              },
              top:parseInt(document.body.offsetHeight*0.7),
              bannerList:[],
              beginTime:Date.parse(new Date())
          }
        },

        mounted() {
            document.title='标小助-首页'
            this.getBannerImage()
            this.$bus.$emit('cardInfo',this.form)
        },

        methods: {
            chat(){
                let url = "https://affim.baidu.com/unique_49213756/mobile/chat?siteId=19557841&userId=49213756&siteToken=efce94ff47728f12a211f329eec7c3ea";
                let name = "标小助"; //网页名称，可为空;
                let iWidth = 414; //弹出窗口的宽度;
                let iHeight = 400; //弹出窗口的高度;
                let iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
                let iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;

                window.open(url, name, 'height=' + iHeight + ',,innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no');

            },


            information(e){
                let form={bidType:0, categoryId:'', keyWord:'', maxPage:'', minPage:'', type:e}
                navigatePhoneUrl('/phoneKeyword',form)
            },

            searchIpt(){
                if (this.searchValue===''){
                    this.$router.push('/phoneSearch')
                    addBehavior('手机端首页搜索','用户搜索：【】',location.href,this.beginTime,Date.parse(new Date()))
                }else{
                    let form={bidType:0, categoryId:'', keyWord:this.searchValue, maxPage:'', minPage:'', type:1}
                    navigatePhoneUrl('/phoneKeyword',form)
                    searchKey(this.searchValue)
                    addBehavior('手机端首页搜索','用户搜索：'+this.searchValue,location.href,this.beginTime,Date.parse(new Date()))
                }
            },

            getBannerImage(){
                GetBanner().then(res=>{
                    let arr=[]
                    if (res){
                      let array= accessGetBanner(res)
                       arr = array.filter(value => {return value.place === 10})
                       this.bannerList=arr
                    }else{
                        this.bannerList=arr
                    }

                }).catch(err=>{})
            },

            bannerUrl(e){
                if (e.url){
                    localStorage.setItem('activityIds',e.id)
                    let a=e.url.split('#/')[1]
                    this.$router.push(a)
                }
            }
        },

        watch:{
            categoryType(newValue,oldValue){
                this.form={
                    pageNum:1,
                    pageSize:10,
                    bidType:0,
                    categoryId:'',
                    keyWord:'',
                    maxPage:'',
                    minPage:'',
                    type:newValue
                }
            }
        },

    }
</script>

<style scoped >

</style>
