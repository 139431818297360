
import service from "@/api/service";

export async function createOrders(value) {
    let res = await service({
        url:'/member/order/createOrder',
        method:'post',
        data:value
    })

    return res.data
}
// native支付
export async function createNative(value) {
    let res = await service({
        url:'/member/pay/createNative',
        method:'post',
        data: {payInfo:value}
    })

    return res.data
}

// 活动数据
export async function getActivityInfoById(value) {
    let res = await service({
        url:'/member/active/getActivityByBanner',
        method:'post',
        params: {id:value}
    })

    return res.data
}
// 判断是否可以参加活动
export async function checkAvailable(activityId,memberId ) {
    let res = await service({
        url:'/member/active/checkAvailable',
        method:'post',
        params: {activityId,memberId }
    })

    return res.data
}

// 活动创建订单
export async function createOrder(data) {
    let res = await service({
        url:'/member/active/createOrder',
        method:'post',
        data:data
    })

    return res.data
}
// 活动创建订单
export async function createOrderH5(data) {
    let res = await service({
        url:'/member/active/createOrder',
        method:'post',
        data:data
    })

    return res.data
}


// 活动微信Native支付
export async function createActivityNative(data) {
    let res = await service({
        url:'/member/active/createNative',
        method:'post',
        data:data
    })

    return res.data
}

// 活动支付宝Native支付
export async function createActivityAliWebPay(data) {
    let res = await service({
        url:'/member/active/aliWebPay',
        method:'post',
        data:{payInfo: data}
    })

    return res.data
}
// 活动支付宝Native支付
export async function sendConvertDataType(data) {
    let res = await service({
        url:'/member/promoted/sendConvertData',
        method:'post',
        data:{newType: 10}
    })

    return res.data
}
