<template>
    <div>
        <a-modal
                :footer="null"
                width="580px"
                :dialog-style="{ top: '150px',borderRadius:'30px',padding:0}"
                :visible="payTips"
                :maskClosable="false"
                @cancel="() => setModal1Visible(false)"
        >
        <div class="pay_dw_tips">
            <div class="pay_dw_title">{{document.title ? document.title : ''}}</div>
            <div class="pay_dw_top">
                <div class="core">本文档需要支付：<b>{{document.price ? document.price :''}}</b> 标币</div>
                <div class="core">您目前持有：<span>{{userFund ? userFund :0}}</span>标币，是否需要立即下载</div>
                <div class="core_down">
                    <a-button type="primary" @click="download">立即下载</a-button>
                </div>
            </div>
        </div>
        </a-modal>


        <pay-prompt :prompt="prompt" @promptClose="promptClose" :recharge="false"></pay-prompt>
    </div>
</template>

<script>
    import payPrompt from "@/views/page_pay/pay_prompt";
    import './pay_tips.scss'
    import {decrypt} from "@/utils/AES";
    import {addDownLoadLog} from "@/api/page/promoted";
    import {buyBidByFund} from "@/api/page/order";
    export default {
        name: "pay_dw_tips",
        props:['payTips','document','userFund'],
        components:{payPrompt},
        data(){
          return{
              prompt:false,
              userInfo:{}
          }
        },
        methods:{
            setModal1Visible(){
                this.$emit('payTipsClose',true)
            },
            promptClose(){
                this.prompt=false
            },
            download(){
                let userInfo = JSON.parse(decrypt(localStorage.getItem('userInfo')))
                let getBuy={memberId :userInfo.id,memberPhone:userInfo.phone,bidId:this.document.id,bidName:this.document.title,amount:this.document.price}
                buyBidByFund(getBuy).then(res=>{
                    this.prompt=true
                    let elemIF = document.createElement("iframe");
                    elemIF.src = this.document.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                    addDownLoadLog(this.document.id).then(res=>{})
                    this.$bus.$emit('buySuccess',true)
                    this.setModal1Visible()
                }).catch(err=>{})
            }
        },
        mounted() {
            let s = localStorage.getItem('userInfo')
            if (s){
                let userInfo = JSON.parse(decrypt( localStorage.getItem('userInfo')))
                this.userInfo=userInfo
            }
        }
    }
</script>

<style scoped>
    >>>.ant-modal-content {
        border-radius: 20px;
        padding: 0;
    }
    >>>.ant-modal-body {
        padding: 0;
    }
</style>
