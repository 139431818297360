<template>
    <div>
        <view-right-tip></view-right-tip>
        <nav-title @loginGetToken="loginGetToken" @loginOutInfo="loginOutInfo" :nav-top="shotTop" :path-status="routerPath" :login="login"></nav-title>
        <router-view/>
        <flooter></flooter>
        <advertise
                :is-left="isLeft"
                :is-right="isRight"
                :banner-list="bannerList"
                @btnClose="btnClose"
        ></advertise>
    </div>
</template>

<script>
    import '@/assets/base/app.scss'
    import navTitle from "../../components/comment/navTitle";
    import flooter from "@/views/page_flooter/flooter";
    import viewRightTip from "@/views/page_right/view_right_tip";
    import advertise from "@/components/comment/advertise";
    import {getAdList} from "@/api/page/public";
    import {advertiseSetting} from "@/utils/checked";
    import {getBanner} from "@/api/page/moduleTopic";
    import {accessGetBanner} from "@/data/accessKey";

    export default {
        name: "index",
        components:{navTitle,flooter,viewRightTip,advertise},
        data(){
          return{
              top:0,
              shotTop:false,
              isActive:true,
              routerPath:false,
              login:false,
              bannerList:[],
              isLeft:false,
              isRight:false,
          }
        },
        methods:{
            // 获取广告的位置
            getAdvertise(){
                // getAdList().then(res=>{
                //    let places=[]
                //    let arr= advertiseSetting(res)
                //    arr.forEach(value => {
                //        if (value.place){
                //            let reg=RegExp(',')
                //            if (reg.test(value.place)){
                //                places=value.place.split(',')
                //            }else{
                //                places=[value.place]
                //            }
                //            this.form={imgUrl:value.imgUrl,Url:value.Url,height:value.height,width:value.width,place:value.place,toLeft:value.toLeft,toRight:value.toRight,close:value.close,show:false}
                //        }
                //    })
                //
                //
                //     if (this.$route.path==='/search'){
                //         if (this.form.place.includes('2')){
                //             this.form.show=true
                //         }else{
                //             this.form.show=false
                //         }
                //     }
                //     if (this.$route.path==='/'){
                //         if (this.form.place.includes('1')){
                //             this.form.show=true
                //         }else{
                //             this.form.show=false
                //         }
                //     }
                //     if (this.$route.path==='/content'){
                //         if (this.form.place.includes('3')){
                //             this.form.show=true
                //         }else{
                //             this.form.show=false
                //         }
                //     }
                //     if (this.$route.path==='/user'){
                //         if (this.form.place.includes('4')){
                //             this.form.show=true
                //         }else{
                //             this.form.show=false
                //         }
                //     }
                //     if (this.$route.path==='/custom'){
                //         if (this.form.place.includes('5')){
                //             this.form.show=true
                //         }else{
                //             this.form.show=false
                //         }
                //     }
                //     if (this.$route.path==='/model'){
                //         if (this.form.place.includes('6')){
                //             this.form.show=true
                //         }else{
                //             this.form.show=false
                //         }
                //     }
                //     if (this.$route.path==='/active'){
                //         if (this.form.place.includes('7')){
                //             this.form.show=true
                //         }else{
                //             this.form.show=false
                //         }
                //     }
                //
                // })
            },

            getBannerList(){
                getBanner().then(res => {
                    let s =[]
                    if (res && res.length>0){
                        res.forEach(value=>{
                            if (value.place===8 || value.place===9){
                                s.push(value)
                            }
                        })
                    }
                    if (s.length>0){
                        s.forEach(value => {
                            if (value.place===8){
                                if (this.$route.path==='/'||this.$route.path==='/search'||this.$route.path==='/model'){
                                    this.isLeft=true
                                }else{
                                    this.isLeft=false
                                }

                            }
                            if (value.place===9){
                                if (this.$route.path==='/'||this.$route.path==='/search'||this.$route.path==='/model'){
                                    this.isRight=true
                                }else{
                                    this.isRight=false
                                }

                            }
                        })
                    }

                    this.bannerList=s


                }).catch(err => {})
            },

            btnClose(e){
                if (e.data===0){
                    this.isLeft=false
                }
                if (e.data===1){
                    this.isRight=false
                }
            },
            handleScroll() {

                this.top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);

                // localStorage.getItem('checked') ? this.checkedToken() : this.checkedToken()
                if (this.routerPath){
                    if (this.$route.path ==='/content' || this.$route.path ==='/custom' || this.$route.path==='/user'){
                        this.shotTop=false
                        this.routerPath=false
                    }else{
                        this.top>300 ?  this.shotTop=false :  this.shotTop=true
                    }
                }else{
                    this.shotTop=false
                }

            },
            checkLogin(){
                let s= localStorage.getItem('userInfo')
                let n=localStorage.getItem('accessKey')
                if (s && n){
                    this.login=true
                }

                if (this.$route.path==='/active'){
                    this.isActive=false
                }else{
                    this.isActive=false
                }
            },
            loginGetToken(){this.checkLogin()},
            loginOutInfo(){
                this.login=false
            }
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll);
            this.checkLogin()

            this.getAdvertise()

            this.getBannerList()
        },

        watch:{
            $route:{
                handler(newValue,oldValue){
                    let reg=RegExp('search|content|custom|user')
                    if (reg.test(newValue.path)){
                        if (newValue.path==='/search'){
                            this.routerPath=false
                            this.shotTop=false
                        }else{
                            this.routerPath=true
                            this.shotTop=false
                        }
                    }else{
                        this.routerPath=true
                        this.shotTop=true
                    }

                    if (this.bannerList.length>0){
                        if (newValue.path==='/'||newValue.path==='/search'||newValue.path==='/model'){
                            this.isLeft=true
                        }else{
                            this.isLeft=false
                        }
                    }



                    // if (newValue.path==='/active'){
                    //     this.isActive=false
                    // }else{
                    //     this.isActive=false
                    // }

                },
                deep: true,
                immediate:true
            },
        }
    }
</script>
