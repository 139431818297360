import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dictory:[],
    accessKey:'4plj8EjTOk/O2Zd9Nac56A=='
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
})
