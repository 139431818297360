import {getCaption} from "@/utils/parse";
import {decrypt} from "@/utils/AES";
import router from "@/router";
import {addBehavior} from "@/data/accessKey";

export function dataToObject(e) {

    let form={totalCount:0,dataList:[]}
    form.totalCount= parseInt(e.split(', encryptList')[0].split('=')[1])
    let list=e.split(', encryptList')[1].slice(1).split(',')
    if (list[0]!=='[]}'){
        list.forEach(v=>{
            let data=v.split('encrypt=')[1].split(')')[0]
            form.dataList.push(JSON.parse( decrypt(data)))
        })
    }else{
        form={totalCount:0,dataList:[]}
    }

    return form
}


export function htmlToString(e) {
    return e.replace(/<\/?.+?\/?>|\r|\n|\s*/g,'')
}



// 去除对象里面所有的控制
export function removeEmptyField(obj) {
    var newObj = {}
    if (typeof obj === 'string') {
        obj = JSON.parse(obj)
    }
    if (obj instanceof Array) {
        newObj = []
    }
    if (obj instanceof Object) {
        for (var attr in obj) {
            // 属性值不为'',null,undefined才加入新对象里面(去掉'',null,undefined)
            if (obj.hasOwnProperty(attr) && obj[attr] !== '' && obj[attr] !== null && obj[attr] !== undefined) {
                if (obj[attr] instanceof Object) {
                    // 空数组或空对象不加入新对象(去掉[],{})
                    if(JSON.stringify(obj[attr]) === '{}' || JSON.stringify(obj[attr]) === '[]') {
                        continue
                    }
                    // 属性值为对象,则递归执行去除方法
                    newObj[attr] = removeEmptyField(obj[attr])
                } else if (
                    typeof obj[attr] === 'string' &&
                    ((obj[attr].indexOf('{') > -1 && obj[attr].indexOf('}') > -1) ||
                        (obj[attr].indexOf('[') > -1 && obj[attr].indexOf(']') > -1))
                ) {
                    // 属性值为JSON时
                    try {
                        var attrObj = JSON.parse(obj[attr])
                        if (attrObj instanceof Object) {
                            newObj[attr] = removeEmptyField(attrObj)
                        }
                    } catch (e) {
                        newObj[attr] = obj[attr]
                    }
                } else {
                    newObj[attr] = obj[attr]
                }
            }
        }
    }
    return newObj
}

// 一种神奇的跳转
export function navigateUrl(paths,Object,title,name,url,beginTime) {
    let routeData = router.resolve({ path:paths,query: Object});
    localStorage.setItem('historyUrl',getCaption(routeData.href,'#/'))
    sessionStorage.setItem('historyUrl',getCaption(routeData.href,'#/'))
    window.open(routeData.href, '_blank');
    if (title){
        addBehavior(title,name,url,beginTime,Date.parse(new Date()))
    }

}

// 第二种神奇的跳转
export function navigatePhoneUrl(paths,Object) {
    if (Object.keyWord){
        let arr= localStorage.getItem('phoneKeyWord')
        if (arr){
            if (Object.keyWord !== '' || Object.keyWord){
                let array= JSON.parse(arr)
                if (array.length>20){
                    array.unshift({id:parseInt(Math.random()*1000),name:Object.keyWord})
                    array.pop()
                    localStorage.setItem('phoneKeyWord',JSON.stringify(array))
                    sessionStorage.setItem('historyUrls',paths)
                    router.push({ path:paths,query: Object})
                }else{
                    array.unshift({id:parseInt(Math.random()*1000),name:Object.keyWord})
                    localStorage.setItem('phoneKeyWord',JSON.stringify(array))
                    sessionStorage.setItem('historyUrls',paths)
                    router.push({ path:paths,query: Object})
                }
            }else{
                router.push({ path:paths,query: Object})
            }

        }else{
            if (Object.keyWord !== '' || Object.keyWord){
                let array=[]
                array.unshift({id:parseInt(Math.random()*1000),name:Object.keyWord})
                localStorage.setItem('phoneKeyWord',JSON.stringify(array))
                sessionStorage.setItem('historyUrls',paths)
                router.push({ path:paths,query: Object})
            }else{

                router.push({ path:paths,query: Object})
            }
        }
    }else{
        sessionStorage.setItem('historyUrls',location.href.split('/#')[1])
        router.push({ path:paths,query: Object})
    }

}
