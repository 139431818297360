<template>
    <div>
        <a-modal
                :footer="null"
                width="520px"
                :dialog-style="{ top: '200px',borderRadius:'30px',padding:0}"
                :visible="regShow"
                :maskClosable="false"
                @cancel="() => setModal1Visible(false)"
        >
            <div class="login_main">
                <div class="logo">
                    <img style="width: 150px;height: 58px" src="../../assets/image/img/logo.png" alt="">
                </div>
                <div class="login_chose">
                    <div class="login_phone">
                        <a-row type="flex">
                            <a-col style="margin-bottom: 20px" :flex="4">
                                <a-input v-model="form.phone" size="large" id="ipt" type="text" icon="phone" placeholder="请输入手机号码">
                                    <!--                                    <a-icon style="color: #999999" slot="prefix" type="phone" />-->
                                    <template slot="prefix">
                                        <img class="left_icon" src="../../assets/image/img/24gl-phoneLoudspeaker.png" alt="">
                                    </template>
                                </a-input>
                            </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col style="margin-bottom: 20px" :flex="4">
                                <a-input v-model="form.password" size="large" id="ipt_pwd" type="text" icon="phone" placeholder="请输入密码">
                                    <!--                                    <a-icon style="color: #999999" slot="prefix" type="phone" />-->
                                    <template slot="prefix">
                                        <img class="left_icon" src="../../assets/image/img/icon_pws.png" alt="">
                                    </template>
                                </a-input>
                            </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col style="margin-bottom: 30px" >
                                <a-col :span="18">
                                    <a-input v-model="form.code" size="large" id="ipt_code" type="text" icon="phone" placeholder="请输入验证码">
                                        <template slot="prefix">
                                            <img class="left_icon" src="../../assets/image/img/icon_yzm_1.png" alt="">
                                        </template>
                                    </a-input>
                                </a-col>
                                <a-col :span="6">
                                    <a-button :disabled="codeStatus" @click="sendPhoneCode" class="ipt_btn" type="primary">
                                        {{codeStatus ? `${codeTime}秒 后重试` :'发送验证码'}}
                                    </a-button>
                                </a-col>
                            </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col  >
                                <a-col :span="18">
                                    <a-button :loading="loading" @click="submitReg" class="submitBtn" type="primary">注册并登录</a-button>
                                </a-col>
                            </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col  >
                                <a-col :span="24">
                                    <div class="tip">
                                        <a-checkbox v-model="checked" @change="onChange"></a-checkbox>
                                        注册即代表同意
                                        <a-button @click="about(label.id)" type="link">《注册声明》</a-button>
                                        <a-button @click="about(label.id)" type="link">《版权声明》</a-button>
                                        <a-button @click="about(label.id)" type="link">《隐私政策》</a-button>
                                    </div>

                                </a-col>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </div>
        </a-modal>

        <reg_tips :reg-status="regTips" :core="core" @regSuccess="regSuccess"></reg_tips>
    </div>
</template>

<script>
    import {checkPhone} from "@/utils/voilte";
    import {activityGiveCore, addLoginLog, getUserInfo, memberLogin, registerLogin, sendMessage} from "@/api/page/sso";
    import {decrypt, encrypt} from "@/utils/AES";
    import {sendPostReg} from "@/api/phone/promoteKey";
    import {promoteBaiduInfo} from "@/utils/promote";
    import {checkExistByPhone} from "@/api/phone/public";

    import reg_tips from "@/views/page_login/reg_tips";
    import {getAgreement} from "@/api/page/public";
    import {getRandomCode} from "@/utils/RandomCode";

    export default {
        props:['regShow'],
        name: "reg",
        components:{reg_tips},
        data(){
            return{
                form:{phone:'',password:'',code:''},
                codeTime:60, //验证码倒计时
                codeStatus:false, //验证码状态
                checked:true,//是否勾选协议
                timeOut:'',//倒计时清空
                loading:false,
                beginTime:Date.parse(new Date()),
                regTips:false,
                label:{},
                core:0
            }
        },
        mounted() {
            getAgreement().then(res=>{
                if (res){
                    this.label = res[0]
                }
            }).catch(error =>{} )
        },
        methods:{
            setModal1Visible(){
                this.$emit('submitRegClose',false)
            },
            onChange(e) {
                // console.log(decrypt('GWsZaPf9bQMKXJLYC64PFQnnOjaE6RvLUhFl8hR+ajt2Krm5ydytIBJRFoIsS70T'))
                this.checked=e.target.checked
            },

            about(e){
                this.$router.push({path: '/webState', query: {change: e}})
                this.setModal1Visible()
            },
            submitReg(){
                const bdCode = getRandomCode()
                if (this.checked){
                  if (this.form.phone===''){
                      this.$message.warning('手机号码为空');
                  }else if (this.form.password===''){
                      this.$message.warning('密码未填写');
                  }else if (this.form.code!==decrypt(localStorage.getItem('code'))){
                      this.$message.warning('验证码填写错误');
                  }else {
                      let s= checkPhone(this.form.phone)
                      if (s){
                          this.loading=true
                          this.givenCore(this.form.phone)
                          checkExistByPhone(this.form.phone).then(status=>{
                              if (status){
                                  registerLogin({phone:this.form.phone,password:this.form.password},bdCode).then(res=>{
                                      if (res.code===500){
                                          this.$message.warning(res.message);
                                          this.loading=false
                                      }else{

                                          memberLogin({username:this.form.phone,password:this.form.password}).then(res=>{
                                              if (res.code===500){
                                                  this.loading=false
                                                  this.$message.warning(res.message);
                                              }else{
                                                  localStorage.setItem('accessKey',res)
                                                  addLoginLog(getRandomCode()).then()
                                                  getUserInfo().then(response=>{
                                                      localStorage.setItem('userInfo',response)

                                                      // 登录完成后
                                                      this.$emit('loginSuccess',true)
                                                      this.setModal1Visible()
                                                      this.loading=false
                                                      // 这里可以添加推广记录
                                                      sendPostReg(1,true)

                                                  }).catch(err=>{ this.loading=false;})
                                              }
                                          }).catch(err=>{
                                              this.$message.warning(err.message);
                                          })

                                          //     localStorage.setItem('accessKey',res)
                                          //     getUserInfo().then(response=>{
                                          //         localStorage.setItem('userInfo',response)
                                          //         // 登录完成后
                                          //         this.$emit('loginSuccess',true)
                                          //         this.setModal1Visible()
                                          //         this.loading=false
                                          //     }).catch(err=>{ this.loading=false})
                                      }
                                  }).catch(err=>{this.loading=false})
                              }else{
                                  this.givenCore(this.form.phone)

                                  registerLogin({phone:this.form.phone,password:this.form.password},bdCode).then(res=>{
                                      if (res.code===500){
                                          this.$message.warning(res.message);
                                          this.loading=false
                                      }else{
                                          memberLogin({username:this.form.phone,password:this.form.password}).then(res=>{
                                              if (res.code===500){
                                                  this.loading=false
                                                  this.$message.warning(res.message);
                                              }else{
                                                  localStorage.setItem('accessKey',res)
                                                  addLoginLog(getRandomCode()).then()
                                                  getUserInfo().then(response=>{
                                                      localStorage.setItem('userInfo',response)

                                                      // 登录完成后
                                                      this.$emit('loginSuccess',true)
                                                      this.setModal1Visible()
                                                      this.loading=false
                                                      // 这里可以添加推广记录
                                                      sendPostReg(1,false)

                                                  }).catch(err=>{ this.loading=false;})
                                              }
                                          }).catch(err=>{
                                              this.$message.warning(err.message);
                                          })

                                          //     localStorage.setItem('accessKey',res)
                                          //     getUserInfo().then(response=>{
                                          //         localStorage.setItem('userInfo',response)
                                          //         // 登录完成后
                                          //         this.$emit('loginSuccess',true)
                                          //         this.setModal1Visible()
                                          //         this.loading=false
                                          //     }).catch(err=>{ this.loading=false})
                                      }
                                  }).catch(err=>{this.loading=false})
                              }
                          })

                      }else {
                          this.$message.warning('手机号码填写不正确');
                      }
                  }

              }else {
                  this.$message.warning('协议未勾选');
              }
            },


            // 短信发送
            sendPhoneCode(){
                let s= checkPhone(this.form.phone)

                if (s){
                    this.codeStatus=true
                    sendMessage(this.form.phone).then(res=>{
                        localStorage.setItem('code',res)
                        this.timeOut=setInterval(()=>{
                            if (this.codeTime>0){
                                this.codeTime-=1
                            }else{
                                clearInterval(this.timeOut)
                                this.codeTime=60
                                this.codeStatus=false
                            }
                        },1000)
                    }).catch(err=>{})
                }else {
                    this.$message.warning('手机号填写不正确');
                }
            },

            regSuccess(){
                // 关闭弹框
                this.regTips=false
            },

            // 是否是活动进入送笔的
            givenCore(phone){
                activityGiveCore(phone).then(res=>{

                    if (res.status){
                        setTimeout(()=>{
                            this.regTips=res.status
                            this.core = res.giveFund
                        },1000)

                    }else{
                        this.regTips=false
                    }

                }).catch(err=>{})
            }

        },
    }
</script>

<style scoped>
>>>.ant-modal-content {
    border-radius: 18px;
    padding: 10px;
    box-sizing: border-box;
}
</style>