<template>
    <div id="routers">
        <div class="flooter_main">
            <div class="flooter_left">
                <div class="flooter_logo">
                    <img src="../../assets/image/img/logo1.png" alt="">
                </div>
                <div class="flooter_phone">
                    <div class="flooter_phones">
                        <img src="../../assets/image/img/24gf-phoneLoudspeaker.png" alt="">
                        联系我们：
                    </div>
                    <div class="flooter_phone_tel">{{tel}}</div>
                </div>
            </div>
            <div class="flooter_center">
                <div class="flooter_title">关于我们</div>
                <div class="flooter_content">
                    {{company}}，是一家专注于投标服务的创新型互联网企业，主营业务为：覆盖各行业的投标方案研发、标书定制服务及相关咨询服务。团队有着丰富的研发经验，擅长根据客户实际需求进行定制，为客户创造价值！
                    <p>公司地址:{{address}}</p>
                </div>
            </div>
            <div class="flooter_right">
                <div class="flooter_link_title">协议条款</div>
                <div class="flooter_link">
                    <a-button v-for="(item,index) in table" :key="index" type="link" @click="getCompanyInformation(item.id)">
                        {{item.name}}</a-button>

                </div>
            </div>
            <div class="flooter_ewm">
                <div class="flooter_img">
                    <img src="../../assets/image/img/icon_ewm.png" alt="">
                </div>
                <div class="flooter_tip">关注公司微信查看最新消息</div>
            </div>
        </div>

        <div class="bottom_flooter">
            <div class="bottom_center">
                <a-button type="link" @click="goPolice">{{url}}</a-button>
                <a-button type="link" @click="goPolice">{{pNum}}</a-button>
                <!--                <a-button type="link">电信业务许可证2023005329</a-button>-->
            </div>
        </div>

    </div>
</template>

<script>
    // import {decrypt} from "../../../utils/aes";

    import {getAgreement} from "@/api/page/public";

    export default {
        name: "flooter",
        data() {
            return {
                isCustom: false,
                tel:process.env.VUE_APP_PHONE,
                company:process.env.VUE_APP_COMPANY,
                address:process.env.VUE_APP_COMPANY_ADDRESS,
                url:process.env.VUE_APP_POLICE,
                pNum:process.env.VUE_APP_POLICE_NUM,
                table:[]
            }
        },
        watch: {
            $route(to, from) {
                this.isCustom = to.name === 'custom';
            }
        },
        methods: {
            getCompanyInformation(e) {
                this.$router.push({path: '/webState', query: {change: e}})
            },
            goPolice(){
                location.href='https://beian.miit.gov.cn/'
            }
        },
        mounted() {
            this.isCustom = this.$route.name === 'custom';
            // console.log(decrypt('pzbOTfNALS1TiHtJQMpDdTKJFQrNhEXhxtldtGEj2ewOq4bccSMoI88T3cDsHDlEZSdjcERnzK4Y9EwPVMgv2ufIPAmCEz3Gv00FK0U19bGPSk8K5j7ebaFe9vyH/f3IUau8xJNl2fzhnD1nzx77BOpSFKhMfjeM0T8IZjvMyS/9TuE2Sl0H1tZBWMRuHWCX57NQyjUaGhkV5yL5T/ilySVWIsy0zFpXbH/jOHVtCRWUr1qn1c+igB/09JtrGsDbNImtn8S2gF6HoNhEqDRUL5qi6K96EYS/X5Rj1ju3bC9dO7H+xBENH8jP1Zw9uHMhRcYk7sg20eA2BLAks28SFq8OoRi+VNkJ9FYMVwwQFXK9zgr2Pw/1DXJzV9p1DOr7xFNrFpL+wobnkRDqYF1y0cCdclomWjmsqIBvkEW2H2OOpJRjV8qyjAEKoLtjl1q1CH7VBA8QbSpTj5hxcLnjZjMMh+cxhJsJFEQUv9L31nFpId6DxcghGZCO3nVW6+ly3CZJQwlNEz8X6yLmNsTguLacZK+Pend80sRYRHXpQi2SWMgr6nL98lvKVE6UBd2kzQtkpKpJlVqQBv4Sget25UTQgBaXa0bk8BM7weHUDlU='))
            getAgreement().then(res=>{
                if (res){
                    this.table=res.slice(0,4)
                }
            })

        }
    }
</script>

<style scoped lang="scss">
    #routers{
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 666;
        background: #fff;
        .flooter_main{
            width: 1280px;
            height: 300px;
            display: flex;
            align-items: center;
            .flooter_left{
                width: 300px;
                height: 260px;
                .flooter_logo{
                    width: 100%;
                    display: flex;
                    height: 150px;
                    align-items: center;
                    img{
                        height: 50px;
                    }
                }
                .flooter_phone{
                    padding: 0 0 0 20px;
                    box-sizing: border-box;
                    .flooter_phones{
                        width: 100%;
                        height: 30px;
                        display: flex;
                        color: #003200;
                        font-size: 16px;
                        img{
                            height: 26px;
                            margin-right: 10px;
                        }
                    }
                    .flooter_phone_tel{
                        font-size: 26px;
                        font-weight: 600;
                        color: #333;
                    }
                }
            }
            .flooter_center{
                width: 600px;
                height: 300px;
                .flooter_title{
                    width: 100%;
                    font-size: 16px;
                    height: 100px;
                    padding: 70px 0 0 0 ;
                    box-sizing: border-box;
                    color: #333;
                    font-weight: 600;
                    margin-bottom: 20px;
                }
                .flooter_content{
                    color: #333;
                    font-size: 14px;
                    text-indent: 32px;
                    line-height: 26px;
                    p{
                        color: #333;
                        margin-top: 10px;
                        font-weight: 500;
                    }
                }
            }
            .flooter_right{
                width: 180px;
                height: 300px;
                .flooter_link_title{
                    width: 100%;
                    height: 100px;
                    padding: 70px 0 0 0;
                    box-sizing: border-box;
                    text-align: center;
                    font-weight: 600;
                    font-size: 16px;
                }
                .flooter_link{
                    button{
                        width: 100%;
                        color: #333;
                        margin-top: 5px;
                    }
                }
            }
            .flooter_ewm{
                width: 200px;
                height: 300px;
                .flooter_img{
                    height: 220px;
                    padding: 70px 0 0 0;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    img {
                        height:  150px;
                    }
                }
                .flooter_tip{
                    width: 100%;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                }
            }
        }
        .bottom_flooter{
            width: 100%;
            height: 70px;
            margin-top: -20px;
            display: flex;
            justify-content: center;
            .bottom_center{
                width: 1000px;
                height: 70px;
                border-top: 1px solid #f5f5f5;
                display: flex;
                justify-content: center;
                align-items: center;
                button{
                    color: #333;
                }
            }
        }
    }
</style>



<!--<template>-->
<!--    <div id="routers">-->
<!--        <div class="flooter_main">-->
<!--            <div class="flooter_left">-->
<!--                <div class="flooter_logo">-->
<!--                    <img src="../../assets/image/img/logo.png" alt="">-->
<!--                </div>-->
<!--                <div class="flooter_phone">-->
<!--                    <div class="flooter_phones">-->
<!--                        <img src="../../assets/image/img/24gf-phoneLoudspeaker.png" alt="">-->
<!--                        联系我们：-->
<!--                    </div>-->
<!--                    <div class="flooter_phone_tel">{{tel}}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="flooter_center">-->
<!--                <div class="flooter_title">关于我们</div>-->
<!--                <div class="flooter_content">-->
<!--                   {{company}}，是一家专注于投标服务的创新型互联网企业，主营业务为：覆盖各行业的投标方案研发、标书定制服务及相关咨询服务。团队有着丰富的研发经验，擅长根据客户实际需求进行定制，为用户为客户创造价值！-->
<!--                   <p>公司地址:{{address}}</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="flooter_right">-->
<!--                <div class="flooter_link_title">协议条款</div>-->
<!--                <div class="flooter_link">-->

<!--                    <a-button v-for="(item,index) in tableData" :key="index" type="link"-->
<!--                              @click="getCompanyInformation(item)">-->
<!--                        {{item.name}}-->
<!--                    </a-button>-->

<!--                </div>-->
<!--            </div>-->
<!--            <div class="flooter_ewm">-->
<!--                <div class="flooter_img">-->
<!--                    <img src="../../assets/image/img/icon_ewm.png" alt="">-->
<!--                </div>-->
<!--                <div class="flooter_tip">关注公司微信</div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="bottom_flooter">-->
<!--            <div class="bottom_center">-->
<!--                <a-button type="link" @click="goPolice">{{url}}</a-button>-->
<!--                <a-button type="link" @click="goPolice">{{pNum}}</a-button>-->
<!--&lt;!&ndash;                <a-button type="link">电信业务许可证2023005329</a-button>&ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->

<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--    // import {decrypt} from "../../../utils/aes";-->

<!--    import {getAgreement} from "@/api/page/public";-->

<!--    export default {-->
<!--        name: "flooter",-->
<!--        data() {-->
<!--            return {-->
<!--                isCustom: false,-->
<!--                tableData:[],-->
<!--                tel:process.env.VUE_APP_PHONE,-->
<!--                company:process.env.VUE_APP_COMPANY,-->
<!--                address:process.env.VUE_APP_COMPANY_ADDRESS,-->
<!--                url:process.env.VUE_APP_POLICE,-->
<!--                pNum:process.env.VUE_APP_POLICE_NUM-->
<!--            }-->
<!--        },-->
<!--        watch: {-->
<!--            $route(to, from) {-->
<!--                this.isCustom = to.name === 'custom';-->
<!--            }-->
<!--        },-->
<!--        methods: {-->
<!--            getCompanyInformation(e) {-->
<!--                this.$router.push({path: '/webState', query: {change: e.id}})-->
<!--            },-->
<!--            goPolice(){-->
<!--                location.href='https://beian.miit.gov.cn/'-->
<!--            },-->
<!--            getAgreementList(){-->
<!--                getAgreement().then(res=>{-->
<!--                    if (res.code === 500){-->
<!--                        console.log(res.data)-->
<!--                    }else{-->
<!--                        console.log(res)-->
<!--                        this.tableData=res.slice(0,4)-->
<!--                    }-->
<!--                })-->
<!--            },-->
<!--        },-->
<!--        mounted() {-->
<!--            this.isCustom = this.$route.name === 'custom';-->
<!--            this.getAgreementList()-->
<!--            // console.log(decrypt('pzbOTfNALS1TiHtJQMpDdTKJFQrNhEXhxtldtGEj2ewOq4bccSMoI88T3cDsHDlEZSdjcERnzK4Y9EwPVMgv2ufIPAmCEz3Gv00FK0U19bGPSk8K5j7ebaFe9vyH/f3IUau8xJNl2fzhnD1nzx77BOpSFKhMfjeM0T8IZjvMyS/9TuE2Sl0H1tZBWMRuHWCX57NQyjUaGhkV5yL5T/ilySVWIsy0zFpXbH/jOHVtCRWUr1qn1c+igB/09JtrGsDbNImtn8S2gF6HoNhEqDRUL5qi6K96EYS/X5Rj1ju3bC9dO7H+xBENH8jP1Zw9uHMhRcYk7sg20eA2BLAks28SFq8OoRi+VNkJ9FYMVwwQFXK9zgr2Pw/1DXJzV9p1DOr7xFNrFpL+wobnkRDqYF1y0cCdclomWjmsqIBvkEW2H2OOpJRjV8qyjAEKoLtjl1q1CH7VBA8QbSpTj5hxcLnjZjMMh+cxhJsJFEQUv9L31nFpId6DxcghGZCO3nVW6+ly3CZJQwlNEz8X6yLmNsTguLacZK+Pend80sRYRHXpQi2SWMgr6nL98lvKVE6UBd2kzQtkpKpJlVqQBv4Sget25UTQgBaXa0bk8BM7weHUDlU='))-->
<!--        }-->
<!--    }-->
<!--</script>-->

<!--<style scoped lang="scss">-->
<!--    #routers{-->
<!--        width: 100%;-->
<!--        height: 350px;-->
<!--        display: flex;-->
<!--        justify-content: center;-->
<!--        flex-wrap: wrap;-->
<!--        position: relative;-->
<!--        z-index: 666;-->
<!--        background: #fff;-->
<!--        .flooter_main{-->
<!--            width: 1280px;-->
<!--            height: 300px;-->
<!--            display: flex;-->
<!--            align-items: center;-->
<!--            .flooter_left{-->
<!--                width: 300px;-->
<!--                height: 260px;-->
<!--                .flooter_logo{-->
<!--                    width: 100%;-->
<!--                    display: flex;-->
<!--                    height: 150px;-->
<!--                    align-items: center;-->
<!--                    img{-->
<!--                        height: 50px;-->
<!--                    }-->
<!--                }-->
<!--                .flooter_phone{-->
<!--                    padding: 0 0 0 20px;-->
<!--                    box-sizing: border-box;-->
<!--                    .flooter_phones{-->
<!--                        width: 100%;-->
<!--                        height: 30px;-->
<!--                        display: flex;-->
<!--                        color: #003200;-->
<!--                        font-size: 16px;-->
<!--                        img{-->
<!--                            height: 26px;-->
<!--                            margin-right: 10px;-->
<!--                        }-->
<!--                    }-->
<!--                    .flooter_phone_tel{-->
<!--                        font-size: 26px;-->
<!--                        font-weight: 600;-->
<!--                        color: #333;-->
<!--                    }-->
<!--                }-->
<!--            }-->
<!--            .flooter_center{-->
<!--                width: 600px;-->
<!--                height: 300px;-->
<!--                .flooter_title{-->
<!--                    width: 100%;-->
<!--                    font-size: 16px;-->
<!--                    height: 100px;-->
<!--                    padding: 70px 0 0 0 ;-->
<!--                    box-sizing: border-box;-->
<!--                    color: #333;-->
<!--                    font-weight: 600;-->
<!--                    margin-bottom: 20px;-->
<!--                }-->
<!--                .flooter_content{-->
<!--                    color: #333;-->
<!--                    font-size: 14px;-->
<!--                    text-indent: 32px;-->
<!--                    line-height: 26px;-->
<!--                    p{-->
<!--                        color: #333;-->
<!--                        margin-top: 10px;-->
<!--                        font-weight: 500;-->
<!--                    }-->
<!--                }-->
<!--            }-->
<!--            .flooter_right{-->
<!--                width: 180px;-->
<!--                height: 300px;-->
<!--                .flooter_link_title{-->
<!--                    width: 100%;-->
<!--                    height: 100px;-->
<!--                    padding: 70px 0 0 0;-->
<!--                    box-sizing: border-box;-->
<!--                    text-align: center;-->
<!--                    font-weight: 600;-->
<!--                    font-size: 16px;-->
<!--                }-->
<!--                .flooter_link{-->
<!--                    button{-->
<!--                        width: 100%;-->
<!--                        color: #333;-->
<!--                        margin-top: 5px;-->
<!--                    }-->
<!--                }-->
<!--            }-->
<!--            .flooter_ewm{-->
<!--                width: 200px;-->
<!--                height: 300px;-->
<!--                .flooter_img{-->
<!--                    height: 220px;-->
<!--                    padding: 70px 0 0 0;-->
<!--                    box-sizing: border-box;-->
<!--                    display: flex;-->
<!--                    justify-content: center;-->
<!--                    img {-->
<!--                        height:  150px;-->
<!--                    }-->
<!--                }-->
<!--                .flooter_tip{-->
<!--                    width: 100%;-->
<!--                    height: 40px;-->
<!--                    text-align: center;-->
<!--                    line-height: 40px;-->
<!--                }-->
<!--            }-->
<!--        }-->
<!--        .bottom_flooter{-->
<!--            width: 100%;-->
<!--            height: 70px;-->
<!--            margin-top: -20px;-->
<!--            display: flex;-->
<!--            justify-content: center;-->
<!--            .bottom_center{-->
<!--                width: 1000px;-->
<!--                height: 70px;-->
<!--                border-top: 1px solid #f5f5f5;-->
<!--                display: flex;-->
<!--                justify-content: center;-->
<!--                align-items: center;-->
<!--                button{-->
<!--                    color: #333;-->
<!--                }-->
<!--            }-->
<!--        }-->
<!--    }-->
<!--</style>-->
