import axios from "axios";

import router from "@/router";

const service = axios.create({
    timeout: 16000,
    // baseURL: process.env.VUE_APP_API_ROOT,
    // baseURL:'/',
    // headers: {'Content-Type': 'application/json'}
})


service.interceptors.request.use(config => {

        let  Reg= RegExp('phone')
        if (Reg.test(location.href)){
            if (localStorage.getItem('accessKeyPhone')){
                config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessKeyPhone')
                config.headers['Content-Type'] = 'application/json';
            }
        }else{
            if (localStorage.getItem('accessKey')){
                config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessKey')
                config.headers['Content-Type'] = 'application/json';
            }
        }

        return config
    }, error => {
        Promise.reject(error)
    }
)

service.interceptors.response.use(
    response=>{

        if (response.data.code ===200 && response.status===200){
            return response.data
        }
        if (response.data.code===401 || response.status===401){
            if (localStorage.getItem('accessKey')){
                localStorage.removeItem('accessKey')
                router.push('/').catch(err=>{})
            }
            return  Promise.resolve(response)
        }
        if (response.data.code===500 || response.status===500){

            return  Promise.resolve(response)
        }
        if (response.data.code===405 || response.status===405){

            return  Promise.resolve(response)
        }

        return Promise.resolve(response);
    },
    error => {

        return Promise.reject(error)
    })


export default service
