import service from "@/api/service";

// banner图
export async function getBanner(e) {
    let res=await service({
        url:'/member/moduleTopic/banner',
        method: 'post',
    });

    return res.data
}

// 投标方案
export async function bidProposal() {
    let res=await service({
        url:'/member/moduleTopic/bidProposal',
        method: 'post',
    });

    return res.data
}

// 标书类别
export async function BidModule() {
    let res=await service({
        url:'/member/moduleTopic/module',
        method: 'post',
    });
    return res.data
}
export async function BidModuleReplace() {
    let res=await service({
        url:'/member/moduleTopic/moduleReplace',
        method: 'post',
    });

    return res.data
}
// 分类树
export async function getCategoryTree(data) {
    let res=await service({
        url:'/member/pub/getCategoryTree',
        method: 'post',
        params:data
    });

    return res.data
}

// 根据分类查找文档
export async function getListByCategory(value) {
    let res=await service({
        url:'/member/moduleTopic/getListByCategory',
        method: 'post',
        data:value
    });

    return res.data
}


// 根据分类查找文档
export async function getBidByIdDetail(value) {
    let res=await service({
        url:'/member/moduleTopic/getBidById',
        method: 'post',
        params: {id:value}
    });

    return res.data
}

// 添加用户收藏
export async function addCollectLog(value) {
    let res=await service({
        url:'/member/moduleTopic/addCollectLog',
        method: 'post',
        params: {bidId :value}
    });

    return res.data
}
// 取消用户收藏
export async function cancelCollectLog(value) {
    let res=await service({
        url:'/member/moduleTopic/cancelCollectLog',
        method: 'post',
        params: {bidId :value}
    });

    return res.data
}

// 取消用户收藏
export async function getBidByModuleId(BidId,page) {
    let res=await service({
        url:'/member/moduleTopic/getBidByModuleId',
        method: 'post',
        params: {moduleId :BidId,pageNum:page,pageSize:10}
    });

    return res.data
}

// 文档分类列表
export async function queryCriteria(BidId) {
    let res=await service({
        url:'/member/moduleTopic/queryCriteria',
        method: 'post',
        params: {bidType :BidId}
    });

    return res.data
}

// 添加文档浏览记录（weidenglu）
export async function addViewCount(BidId) {
    let res=await service({
        url:'/member/moduleTopic/addViewCount',
        method: 'post',
        params: {bidId:BidId}
    });

    return res.data
}
// 添加文档浏览记录（yidenglu）
export async function addViewsByUser(BidId) {
    let res=await service({
        url:'/member/moduleTopic/addViewsByUser',
        method: 'post',
        params: {bidId:BidId}
    });

    return res.data
}

// 文档分类查询（yidenglu）
export async function correlation(BidId) {
    let res=await service({
        url:'/member/moduleTopic/correlation',
        method: 'post',
        params: {bidId:BidId}
    });

    return res.data
}

// 添加反馈
export async function addfeedback(data) {
    let res=await service({
        url:'/member/moduleTopic/addFeedback',
        method: 'post',
        data: data
    });

    return res.data
}

