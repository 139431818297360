<template>
    <div>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text=""
                @load="onLoad"
                :offset="100"
        >
            <div v-if="tableData.length>0" @click="contents(item)" v-for="(item,index) in tableData" :key="index"
                 class="cards">
                <div class="card_top">
                    <img src="../../assets/image/h5/word.png" alt="">
                    <span>{{ item.title.length > 15 ? item.title.slice(0, 15) + '...' : item.title }}</span>
                </div>
                <div class="card_bottom">
                    <div class="card_bottom_left">
                        <span>{{ item.author.length > 9 ? item.author.slice(0,9) : item.author}}</span>
                        <span><img src="../../assets/image/h5/doc.png" alt="">{{ item.pages }} 页</span>
                    </div>
                    <div class="card_bottom_right">
                        <img src="../../assets/image/h5/bxz.png" alt="">
                        标小助
                    </div>
                </div>
            </div>

            <div v-if="tableData.length<1"
                 style="width: 100%;min-height: 3rem;display: flex;align-items: center;flex-wrap: wrap">
                <img src="../../assets/image/h5/empty.png" alt="">
                <p style="color: #999;font-size: 0.14rem;padding: 0.6rem;box-sizing: border-box;height: 0.2rem">
                    如果您找不到资料,可以在线咨询我们,我们可以为您量身定制标书/方案</p>
                <p style="width: 100%;text-align: center">
                    <a-button @click="$router.push('/phoneCustom')" type="primary"
                              style="width:0.92rem;height: 0.33rem ;">了解详情
                    </a-button>
                </p>
            </div>

        </van-list>
    </div>
</template>

<script>
import {addViewsByUserPhone, getListByCategory} from "@/api/phone/modelTopic";
import {navigatePhoneUrl, removeEmptyField} from "@/utils/resToData";
import {parseTime} from "@/utils/parse";
import {Toast} from "vant";


export default {
    name: "card",
    props: ['searchForm', 'status'],
    data() {
        return {
            img: require('../../assets/image/h5/empty.png'),
            form: {
                pageNum: 1,
                pageSize: 10,
                bidType: 0,
                categoryId: '',
                keyWord: '',
                maxPage: '',
                minPage: '',
                type: 0
            },
            totalCount: 0,
            loading: false,
            finished: false,
            tableData: []
        }
    },

    mounted() {
        // this.getByCategory(this.form)
        this.$bus.$on('cardInfo', data => {
            this.form.pageNum = 1
            this.tableData = []
            this.getByCategory(data)
            this.finished = false
            this.form = data
        })
    },

    destroyed() {
        this.$bus.$off('cardInfo')
    },

    methods: {
        contents(item) {
            sessionStorage.removeItem('historyUrls')
            navigatePhoneUrl('/phoneContent', {doc: item.id})

            // this.$router.push('/phoneContent')
        },

        onLoad() {
            setTimeout(() => {
                this.loading = false;
                if (this.form.pageNum < this.totalCount) {
                    if (this.form.pageNum > 1) {
                        this.getByCategory(this.form)
                    }
                    this.form.pageNum += 1
                } else if (this.form.pageNum === this.totalCount) {
                    if (this.form.pageNum > 1) {
                        this.getByCategory(this.form)
                    }
                    this.finished = true;
                }
            }, 800)

        },

        getByCategory(data) {
            const toast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '加载中...',
            });

            getListByCategory(removeEmptyField(data)).then(res => {
                Toast.clear();
                this.totalCount = Math.ceil(res.totalCount / 10)
                if (res.totalCount > 0) {
                    res.H5BidList.forEach(value => {
                        value.createTime = parseTime(value.createTime)
                    })

                    this.tableData.push(...res.H5BidList)
                } else {
                    this.tableData = []
                }

            }).catch(err => {
            })
        },
    },

    watch: {
        searchForm(newValue, oldValue) {
            this.finished = false
            this.tableData = []
            this.form = newValue
            this.getByCategory(newValue)
        }
    }
}
</script>

<style scoped lang="scss">
.cards {
  margin-bottom: 0.1rem;
  width: 100%;
  height: 0.95rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 14px 0px rgba(142, 150, 164, 0.03);
  border-radius: 0.1rem;
  opacity: 1;
  padding: 0.15rem;
  box-sizing: border-box;

  .card_top {
    height: 0.36rem;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0 0.1rem 0;
    box-sizing: border-box;
    border-bottom: 1px solid #F3F4F6;

    img {
      height: 0.16rem;
    }

    span {
      font-size: 0.16rem;
      color: #333333;
      margin-left: 0.1rem;
    }
  }

  .card_bottom {
    height: 0.36rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.22rem 0;
    box-sizing: border-box;

    .card_bottom_left {
      color: #999999;
      font-size: 0.14rem;
      display: flex;
      align-items: center;

      span {
        margin-right: 0.12rem;

        img {
          height: 0.14rem;
        }
      }
    }

    .card_bottom_right {
      width: 1rem;
      display: flex;
      align-items: center;
      justify-content: right;
      color: #999999;

      img {
        height: 0.14rem;
      }
    }
  }
}

</style>