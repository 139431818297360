import data from '../data/data'
import {message} from "ant-design-vue";

export function giveCore() {
    message.success(
        `恭喜您已获得 ${data.corn}个标币`,
        5,
    );
}

export function mathRadomeString() {
    const length = 6;
    const chars = 'ADEGIJKLMNOQRSTUVY012346789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}