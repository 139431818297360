import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'home', component: () => import(/* webpackChunkName: "about" */ '../views/page_home/view_home')},
  {path: '/webState', name: 'webState', component: () => import(/* webpackChunkName: "about" */ '../views/page_webstate/view_webstate')},
  {path: '/search', name: 'categorySearch', component: () => import(/* webpackChunkName: "about" */ '../views/page_category_search/category_search')},
  {
    path: '/content',
    name: 'content',
    component: () => import(/* webpackChunkName: "about" */ '../views/page_information/view_information'),
    meta:{
      title:'标小助',
      content: {
        keywords:'标小助',
        description:'标小助'
      }
    }
  },
  {path: '/custom', name: 'custom', component: () => import(/* webpackChunkName: "about" */ '../views/page_custom/custom')},
  {path: '/user', name: 'user', component: () => import(/* webpackChunkName: "about" */ '../views/page_user/view_user')},
  {path: '/model', name: 'model', component: () => import(/* webpackChunkName: "about" */ '../views/page_model/view_model')},
  {path: '/active', name: 'active', component: () => import(/* webpackChunkName: "about" */ '../views/active/active')},
  {path: '/forget', name: 'forget', component: () => import(/* webpackChunkName: "about" */ '../views/page_forget/view_forget')},
  {path: '/forum', name: 'forum', component: () => import(/* webpackChunkName: "about" */ '../views/page_forum/view_forum')},
  {path: '*', name: 'notFund', component: () => import(/* webpackChunkName: "about" */ '../views/notFund/notFund')},
  {path: '/phone', name: 'phone', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_home/phone_home')},
  {path: '/phonePay', name: 'phonePay', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_pay/view_phone_pay')},
  {path: '/phoneUser', name: 'phoneUser', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_user/phone_user')},
  {path: '/phoneRecharge', name: 'phoneRecharge', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_rechage/phone_recharge')},
  {path: '/phoneView', name: 'phoneView', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_view/phone_view')},
  {path: '/phoneCollect', name: 'phoneCollect', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_collect/phone_collect')},
  {path: '/phoneAbout', name: 'phoneAbout', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_about/phone_about')},
  {path: '/phoneInfo', name: 'phoneInfo', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_about/phone_about_detail')},
  {path: '/phonePain', name: 'phonePain', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_pain/phone_pian')},
  {path: '/phoneApply', name: 'phoneApply', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_apply/phone_apply')},
  {path: '/phoneLogin', name: 'phoneLogin', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_login/phone_login')},
  {path: '/phoneSearch', name: 'phoneSearch', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_search/phone_search')},
  {path: '/phoneKeyword', name: 'phoneKeyword', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_search_value/search_value')},
  {path: '/phoneContent', name: 'phoneContent', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_document/phone_document')},
  {path: '/phoneRechargePay', name: 'phoneRechargePay', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_recharge/page_pay')},
  {path: '/phoneCustom', name: 'phoneCustom', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_custom/phone_custom')},
  {path: '/phoneBuy', name: 'phoneBuy', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_buy/phone_buy')},
  {path: '/phoneDownload', name: 'download', component: () => import(/* webpackChunkName: "about" */ '../pages/phone_view/download')},
  {path: '/phoneActive', name: 'phoneActive', component: () => import(/* webpackChunkName: "about" */ '../pages/actives/phone_active')},

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// 针对 push 方法
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// 针对 replace 方法
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default router
