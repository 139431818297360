import service from "@/api/service";
import {encrypt} from "@/utils/AES";

// 短信登录
export async function loginByMessage(value,bdCode) {
    let values = encrypt(value)
    let res = await service({
        url: '/member/sso/member/loginByMessage',
        method: 'post',
        data: {phone: values},
        headers: {
            'bdCode': bdCode // 设置请求头中的code
        },
    })

    return res.data
}

// 短信发送
export async function sendMessage(value) {
    let values = encrypt(value)
    let res = await service({
        url: '/member/pub/sendMessage',
        method: 'post',
        params: {phone: values}
    })

    return res.data
}

// 用户账号密码登录
export async function memberLogin(value,bdCode) {
    let res = await service({
        url: '/member/sso/member/login',
        method: 'post',
        params: value,
        headers: {
            'bdCode': bdCode // 设置请求头中的code
        },
    })

    return res.data
}


// 获取用户信息
export async function getUserInfo() {
    let res = await service({
        url: '/member/sso/member/memberInfo',
        method: 'post',
    })

    return res.data
}

// 扫码登录
export async function scanLogin(value,bdCode) {
    let res = await service({
        url: '/member/sso/member/scanLogin',
        method: 'post',
        data: {wxId: value},
        headers: {
            'bdCode': bdCode // 设置请求头中的code
        },
    })

    return res.data
}

// 扫码登录
export async function checkOpenId(data) {
    let res = await service({
        url: '/member/sso/member/checkOpenId',
        method: 'post',
        params: {openId: data}
    })
    return res.data
}


// 用户注册
export async function registerLogin(value, bdCode) {
    let res = await service({
        url: '/member/sso/member/register',
        method: 'post',
        data: value,
        headers: {
            'bdCode': bdCode // 设置请求头中的code
        },
    })

    return res.data
}

// 用户修改密码
export async function updateInfo(value) {
    let res = await service({
        url: '/member/sso/member/updateInfo',
        method: 'post',
        data: value
    })

    return res.data
}

// 活动注册送笔
export async function activityGiveCore(value) {
    let res = await service({
        url: '/member/sso/member/checkRegGiven',
        method: 'post',
        params: {phone: value}
    })

    return res.data
}

export async function addLoginLog(value) {
    let res = await service({
        url: '/member/sso/member/addLoginLog',
        method: 'post',
        params: {code: value},
    })

    return res.data
}