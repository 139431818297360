<template>
    <div>
        <a-modal
                :footer="null"
                width="520px"
                :dialog-style="{ top: '200px',borderRadius:'30px',padding:0}"
                :visible="loginShow"
                :maskClosable="false"
                @cancel="() => setModal1Visible(false)"
        >
            <div class="login_main">
                <div class="logo">
                    <img style="width: 400px;margin-left:10%;height: 58px" src="../../assets/image/img/logo2.png"
                         alt="">
                </div>
                <div class="login_top">
                    <div class="login_nav" v-for="(item,index) in loginList" :key="index">
                        <a-button @click="active=item.id" :class="active===item.id ? 'btn_active':'btn_active_no'"
                                  type="link">{{ item.label }}
                        </a-button>
                        <div v-show="active===item.id" class="active"></div>
                    </div>
                </div>
                <div class="login_chose">
                    <!--                    <img :src="wxUrl" alt="">-->
                    <iframe v-if="active===0"
                            style="transform: scale(0.6);margin-top: -80px;margin-bottom: -50px;border: none"
                            :src="wxUrl"
                            height="430" width="430"></iframe>

                    <div v-if="active===0" class="tips"><img src="../../assets/image/img/wx.png" alt=""> 使用微信扫码登录
                    </div>
                    <div v-if="active===1" class="login_phone">
                        <a-row type="flex">
                            <a-col style="margin-bottom: 20px" :flex="4">
                                <a-input v-model="form.phone" size="large" id="ipt" type="text" icon="phone"
                                         placeholder="请输入手机号码">
                                    <!--                                    <a-icon style="color: #999999" slot="prefix" type="phone" />-->
                                    <template slot="prefix">
                                        <img class="left_icon" src="../../assets/image/img/24gl-phoneLoudspeaker.png"
                                             alt="">
                                    </template>
                                </a-input>
                            </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col style="margin-bottom: 30px">
                                <a-col :span="18">
                                    <a-input v-model="form.code" size="large" id="ipt_code" type="text" icon="phone"
                                             placeholder="请输入验证码">
                                        <template slot="prefix">
                                            <img class="left_icon" src="../../assets/image/img/icon_yzm_1.png" alt="">
                                        </template>
                                    </a-input>
                                </a-col>
                                <a-col :span="6">
                                    <a-button :disabled="codeStatus" @click="sendPhoneCode" class="ipt_btn"
                                              type="primary">
                                        {{ codeStatus ? `${codeTime}秒 后重试` : '获取验证码' }}
                                    </a-button>
                                </a-col>
                            </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col>
                                <a-col :span="24">
                                    <a-button :loading="loading" @click="submitLoginToCode" class="submitBtn"
                                              type="primary">登录
                                    </a-button>
                                </a-col>
                            </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col>
                                <a-col :span="24">
                                    <div class="tip">
                                        <a-checkbox @change="onChange" v-model="checked"></a-checkbox>
                                        登录即代表同意
                                        <a-button @click="about(label.id)" type="link">《注册声明》</a-button>
                                        <a-button @click="about(label.id)" type="link">《版权声明》</a-button>
                                        <a-button @click="about(label.id)" type="link">《隐私政策》</a-button>
                                    </div>
                                </a-col>
                            </a-col>
                        </a-row>
                    </div>
                    <div v-if="active===2" class="login_phone">
                        <a-row type="flex">
                            <a-col style="margin-bottom: 20px" :flex="4">
                                <a-input v-model="phoneForm.username" size="large" id="ipt_username" type="text"
                                         icon="phone" placeholder="请输入手机号码">
                                    <!--                                    <a-icon style="color: #999999" slot="prefix" type="phone" />-->
                                    <template slot="prefix">
                                        <img class="left_icon" src="../../assets/image/img/24gl-phoneLoudspeaker.png"
                                             alt="">
                                    </template>
                                </a-input>
                            </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col :span="24" style="margin-bottom: 20px">
                                <a-input-password v-model="phoneForm.password" size="large" id="ipt_password"
                                                  icon="phone" placeholder="请输入密码">
                                    <template slot="prefix">
                                        <img class="left_icon" src="../../assets/image/img/icon_pws.png" alt="">
                                    </template>
                                </a-input-password>
                            </a-col>
                        </a-row>
                        <div class="forgetPassword"><a @click="forgetPwd" href="javascript:void (0)">忘记密码？</a></div>
                        <a-row type="flex">
                            <a-col>
                                <a-col :span="24">
                                    <a-button @click="submitLoginToUsername" :loading="loading" class="submitBtn"
                                              type="primary">登录
                                    </a-button>
                                </a-col>
                            </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col>
                                <a-col :span="24">
                                    <div class="tip">
                                        <a-checkbox v-model="checked" @change="onChange"></a-checkbox>
                                        登录即代表同意
                                        <a-button @click="about(label.id)" type="link">《注册声明》</a-button>
                                        <a-button @click="about(label.id)" b type="link">《版权声明》</a-button>
                                        <a-button @click="about(label.id)" type="link">《隐私政策》</a-button>
                                    </div>

                                </a-col>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </div>
        </a-modal>

        <a-modal
                :footer="null"
                width="800px"
                :dialog-style="{ top: '200px',borderRadius:'30px',padding:0}"
                :visible="authorityShows"
                :closable="false"
                :maskClosable="false"
        >
            <div class="authory">
                <div class="authory_left">
                    <img style="height: 150px" src="../../assets/image/img/gzh.png" alt="">
                </div>
                <div class="authory_right">
                    <div class="logo"><img src="../../assets/image/img/logo.png" alt=""></div>
                    <div class="phone_authory">
                        <a-input v-model="form.phone" placeholder="请输入手机号"></a-input>
                    </div>
                    <div class="code_authory">
                        <a-input v-model="form.code" placeholder="请输入验证码"></a-input>
                        <a-button :disabled="codeStatus" type="primary" @click="sendPhoneCode">
                            {{ codeStatus ? `${codeTime}秒 后重试` : '获取验证码' }}
                        </a-button>
                    </div>
                    <div class="phone_submit">
                        <a-button type="primary" @click="bandPhone">绑定手机号</a-button>
                    </div>
                </div>
            </div>
        </a-modal>


        <a-modal
                :footer="null"
                width="800px"
                :dialog-style="{ top: '200px',borderRadius:'30px',padding:0}"
                :visible="forget"
                @cancel="() => {forget=false}"
                :closable="true"
                :maskClosable="false"
        >
            <div style="width: 200px;font-size: 18px;font-weight: 600">忘记密码</div>
            <div class="authory">

                <div class="authory_left">
                    <img src="../../assets/image/img/gzh.png" alt="">
                </div>
                <div class="authory_right" v-if="forgetStatus===0">
                    <div class="logo"><img src="../../assets/image/img/logo.png" alt=""></div>
                    <div class="phone_authory">
                        <a-input v-model="form.phone" placeholder="请输入手机号"></a-input>
                    </div>
                    <div class="code_authory">
                        <a-input v-model="form.code" placeholder="请输入验证码"></a-input>
                        <a-button :disabled="codeStatus" type="primary" @click="sendPhoneCode">
                            {{ codeStatus ? `${codeTime}秒 后重试` : '获取验证码' }}
                        </a-button>
                    </div>
                    <div class="phone_submit">
                        <a-button type="primary" @click="forgetPassword">下一步</a-button>
                    </div>
                </div>
                <div class="authory_right" v-if="forgetStatus===1">
                    <div class="logo"><img src="../../assets/image/img/logo.png" alt=""></div>
                    <div class="phone_authory">
                        <a-input type="password" v-model="forgetForm.password" placeholder="请输入密码"></a-input>
                    </div>
                    <div class="phone_authory" style="margin-top: 10px">
                        <a-input type="password" v-model="forgetForm.checkPwd" placeholder="请再次输入密码"></a-input>
                    </div>
                    <div class="phone_submit">
                        <a-button type="primary" @click="editPassword">修改密码</a-button>
                    </div>
                </div>
            </div>
        </a-modal>

        <reg_tips :reg-status="regTips" :core="core" @regSuccess="regSuccess"></reg_tips>
    </div>
</template>

<script>

import reg_tips from "@/views/page_login/reg_tips";
import './login.scss'
import {checkPhone} from "@/utils/voilte";
import {
    activityGiveCore, addLoginLog,
    checkOpenId,
    getUserInfo,
    loginByMessage,
    memberLogin,
    scanLogin,
    sendMessage,
    updateInfo
} from "@/api/page/sso";
import {decrypt, decryptForCzx, encrypt} from "@/utils/AES";
import {getAccessTicket, getAccessToken} from "@/api/page/officalAccount";
import {sendPostReg} from "@/api/phone/promoteKey";
import {promoteBaiduInfo} from "@/utils/promote";
import {checkExistByPhone} from "@/api/phone/public";
import {addBehavior} from "@/data/accessKey";
import {getAgreement} from "@/api/page/public";
import {getRandomCode} from "@/utils/RandomCode";

export default {
    name: "login",
    components: {reg_tips},
    props: ['loginShow'],
    data() {
        return {
            active: 0,
            loginList: [{id: 0, label: '微信登录'}, {id: 1, label: '短信登录'}, {id: 2, label: '账号登录'}],
            form: {phone: '', code: ''},
            codeTime: 120, //验证码倒计时
            codeStatus: false, //验证码状态
            checked: true,//是否勾选协议
            timeOut: '',//倒计时清空
            loading: false,
            beginTime: Date.parse(new Date()),
            phoneForm: {username: '', password: ''},
            wxUrl: '',
            label: {},
            accessToken: '',
            chatLogin: '',
            openId: '',
            authorityShows: false,
            forget: false,
            forgetStatus: 0,
            forgetForm: {password: '', checkPwd: '', phone: ''},
            regTips: false,
            core: 0
        }
    },


    methods: {

        setModal1Visible() {
            window.clearInterval(this.chatLogin)
            this.$emit('submitLoginClose', false)
        },

        about(e) {
            this.$router.push({path: '/webState', query: {change: e}})
            this.setModal1Visible()
        },

        onChange(e) {
            this.checked = e.target.checked
        },

        // 短信发送
        sendPhoneCode() {
            let s = checkPhone(this.form.phone)
            if (s) {
                this.codeStatus = true
                sendMessage(this.form.phone).then(res => {
                    localStorage.setItem('code', res)
                    this.timeOut = setInterval(() => {
                        if (this.codeTime > 0) {
                            this.codeTime -= 1
                        } else {
                            clearInterval(this.timeOut)
                            this.codeTime = 120
                            this.codeStatus = false
                        }
                    }, 1000)
                })
            } else {
                this.$message.warning('手机号填写不正确');
            }
        },

        // 短信登录
        submitLoginToCode() {
            const bdCode = getRandomCode()
            if (this.checked) {
                if (this.form.code === '') {
                    this.$message.warning('验证码不能为空');
                    return;
                }
                let s = checkPhone(this.form.phone)
                let access = this.$store.state.accessKey
                this.form.code === decryptForCzx(access) ? localStorage.setItem('code', access) : null
                // this.form.code ==='40090012000' ? localStorage.setItem('code','adsdsdsd') :null
                if (s) {
                    let d = localStorage.getItem('code')
                    if (localStorage.getItem('code')) {
                        if (this.form.code === decrypt(d) || this.form.code === decryptForCzx(access)) {
                            this.loading = true
                            checkExistByPhone(this.form.phone).then(status => {
                                if (status) {
                                    loginByMessage(this.form.phone, bdCode).then(res => {
                                        if (res.code === 500) {
                                            this.$message.warning(res.message);
                                            this.loading = false;
                                        } else {
                                            localStorage.setItem('accessKey', res)
                                            addLoginLog(getRandomCode()).then()
                                            getUserInfo().then(response => {
                                                localStorage.setItem('userInfo', response)
                                                // 登录完成后
                                                this.$emit('loginSuccess', true)
                                                this.$bus.$emit('loginSuccess', true)
                                                this.setModal1Visible()
                                                this.loading = false
                                                // 这里可以添加推广记录
                                                sendPostReg(1, true)
                                                addBehavior('pc端短信登录', '用户使用短信登录' + '收到的验证码为：' + this.form.code, location.href, this.beginTime, Date.parse(new Date()))
                                            }).catch(err => {
                                                this.loading = false;
                                            })
                                        }
                                    }).catch(err => {
                                        this.loading = false;
                                        this.$message.warning(err);
                                    })
                                } else {
                                    this.givenCore(this.form.phone)
                                    loginByMessage(this.form.phone, bdCode).then(res => {
                                        localStorage.setItem('accessKey', res)
                                        addLoginLog(getRandomCode()).then()
                                        getUserInfo().then(response => {
                                            localStorage.setItem('userInfo', response)
                                            // 登录完成后
                                            this.$emit('loginSuccess', true)
                                            this.$bus.$emit('loginSuccess', true)
                                            this.setModal1Visible()
                                            this.loading = false
                                            // 这里可以添加推广记录
                                            sendPostReg(1, false)
                                        }).catch(err => {
                                            this.loading = false;
                                        })
                                    }).catch(err => {
                                        this.loading = false;
                                        this.$message.warning(err);
                                    })
                                }
                            })

                        } else {
                            this.$message.warning('短信验证码未填写或者不正确');
                        }
                    } else {
                        this.$message.warning('验证码未发送')
                    }

                } else {
                    this.$message.warning('手机号填写不正确');
                }
            } else {
                this.$message.warning('请勾选网站协议');
            }
        },

        // 用户账号密码登录
        submitLoginToUsername() {
            if (this.checked) {
                if (this.phoneForm.username === '') {
                    this.$message.warning('用户名不能为空');
                } else if (this.phoneForm.password === '') {
                    this.$message.warning('密码不能为空');
                } else {
                    this.loading = true

                    checkExistByPhone(this.phoneForm.username).then(status => {
                        if (status) {
                            memberLogin({
                                username: this.phoneForm.username,
                                password: this.phoneForm.password
                            }).then(res => {
                                if (res.code === 500) {
                                    this.loading = false
                                    this.$message.warning(res.message);
                                } else {
                                    localStorage.setItem('accessKey', res)
                                    addLoginLog(getRandomCode()).then()
                                    getUserInfo().then(response => {
                                        let a = decrypt(response)
                                        a.substring()
                                        localStorage.setItem('userInfo', response)
                                        // 登录完成后
                                        this.$emit('loginSuccess', true)
                                        this.$bus.$emit('loginSuccess', true)
                                        this.setModal1Visible()
                                        // 这里可以添加推广记录
                                        sendPostReg(1, true)
                                        addBehavior('pc端账号密码登录', '用户使用账号密码登录，登录账号为' + this.phoneForm.username, location.href, this.beginTime, Date.parse(new Date()))
                                        this.loading = false
                                    }).catch(err => {
                                        this.loading = false;
                                    })
                                }
                            }).catch(err => {
                                this.$message.warning(err.message);
                            })
                        } else {
                            memberLogin({
                                username: this.phoneForm.username,
                                password: this.phoneForm.password
                            }).then(res => {
                                if (res.code === 500) {
                                    this.loading = false
                                    this.$message.warning(res.message);
                                } else {
                                    localStorage.setItem('accessKey', res)
                                    addLoginLog(getRandomCode()).then()
                                    getUserInfo().then(response => {
                                        localStorage.setItem('userInfo', response)
                                        // 登录完成后
                                        this.$emit('loginSuccess', true)
                                        this.$bus.$emit('loginSuccess', true)
                                        this.setModal1Visible()
                                        this.loading = false
                                        // 这里可以添加推广记录
                                        sendPostReg(1, false)
                                    }).catch(err => {
                                        this.loading = false;
                                    })
                                }
                            }).catch(err => {
                                this.$message.warning(err.message);
                            })
                        }
                    })


                }
            } else {
                this.$message.warning('请勾选网站协议');
            }
        },

        // 扫码登录
        scanLoginInfo() {
            const bdCode = getRandomCode()
            let resultInfo = []
            if (resultInfo.length > 4) {
                clearInterval(this.chatLogin)
            }
            // res返回一个二维码
            getAccessToken().then(res => {
                this.wxUrl = res[0]
                this.chatLogin = setInterval(() => {
                    getAccessTicket(res[1]).then(response => {
                        if (response.length > 4) {
                            resultInfo = response;
                            resultInfo = encrypt(response)
                            checkOpenId(resultInfo).then(result => {
                                if (result) {
                                    scanLogin(resultInfo, bdCode).then(openIdToken => {
                                        clearInterval(this.chatLogin)
                                        localStorage.setItem('accessKey', openIdToken)
                                        addLoginLog(getRandomCode()).then()
                                        getUserInfo().then(userInfo => {
                                            localStorage.setItem('userInfo', userInfo)
                                            // 登录完成后
                                            this.$emit('loginSuccess', true)
                                            this.$bus.$emit('loginSuccess', true)
                                            this.setModal1Visible()
                                            this.loading = false
                                            // 这里可以添加推广记录
                                            sendPostReg(1, true)
                                            addBehavior('pc端扫码登录', '该用户为已注册的用户，非首次用户', location.href, this.beginTime, Date.parse(new Date()))
                                        })
                                    }).catch(err => {
                                        this.loading = false;
                                    })
                                } else {
                                    this.openId = response
                                    this.authorityShows = true;
                                    this.setModal1Visible()

                                }
                            }).catch(err => {
                                this.$message.error(err.data)
                            })
                        }
                    })
                }, 2000)
            }).catch(err => {
            })
        },

        // 绑定手机号
        bandPhone() {
            const bdCode = getRandomCode()
            let s = checkPhone(this.form.phone)
            if (s) {
                let d = localStorage.getItem('code')
                if (localStorage.getItem('code')) {
                    if (this.form.code === decrypt(d)) {
                        this.loading = true
                        let form = this.openId + ',' + this.form.phone
                        checkExistByPhone(this.form.phone).then(status => {
                            this.givenCore(this.form.phone)
                            if (status) {
                                scanLogin(encrypt(form), bdCode).then(res => {
                                    if (res) {
                                        localStorage.setItem('accessKey', res)
                                        addLoginLog(getRandomCode()).then()
                                        getUserInfo().then(response => {
                                            localStorage.setItem('userInfo', response)
                                            // 登录完成后
                                            this.$emit('loginSuccess', true)
                                            this.$bus.$emit('loginSuccess', true)
                                            this.setModal1Visible()
                                            this.form.phone = this.form.code = ''
                                            this.authorityShows = false
                                            this.loading = false
                                            // 这里可以添加推广记录
                                            sendPostReg(1, true)
                                        })
                                    }
                                }).catch(err => {
                                    this.loading = false;
                                    this.$message.error(err.data)
                                })
                            } else {
                                this.givenCore(this.form.phone)
                                scanLogin(encrypt(form), bdCode).then(res => {
                                    if (res) {
                                        localStorage.setItem('accessKey', res)
                                        addLoginLog(getRandomCode()).then()
                                        getUserInfo().then(response => {
                                            localStorage.setItem('userInfo', response)
                                            // 登录完成后
                                            this.$emit('loginSuccess', true)
                                            this.$bus.$emit('loginSuccess', true)
                                            this.setModal1Visible()
                                            this.form.phone = this.form.code = ''
                                            this.authorityShows = false
                                            this.loading = false
                                            // 这里可以添加推广记录
                                            sendPostReg(1, false)
                                        })
                                    }
                                }).catch(err => {
                                    this.loading = false;
                                })
                            }
                        })


                    } else {
                        this.$message.warning('短信验证码未填写或者不正确');
                    }
                } else {
                    this.$message.warning('验证码未发送')
                }

            } else {
                this.$message.warning('手机号填写不正确');
            }
        },

        // 忘记密码
        forgetPwd() {
            // this.forget=true
            // this.forgetStatus=0
            this.setModal1Visible()
            this.$router.push('/forget')
        },

        forgetPassword() {
            let s = checkPhone(this.form.phone)
            if (s) {
                let d = localStorage.getItem('code')
                if (localStorage.getItem('code')) {
                    if (this.form.code === decrypt(d)) {
                        this.forgetForm.phone = this.form.phone
                        this.forgetStatus = 1
                        localStorage.removeItem('code')
                    } else {
                        this.$message.warning('短信验证码未填写或者不正确');
                    }
                } else {
                    this.$message.warning('验证码未发送')
                }
            } else {
                this.$message.warning('手机号填写不正确');
            }
        },

        editPassword() {
            if (this.forgetForm.password === this.forgetForm.checkPwd) {
                updateInfo({phone: this.forgetForm.phone, password: this.forgetForm.password}).then(res => {
                    if (res.code === 500) {
                        this.$message.error(res.message)
                    } else {
                        this.$message.success('修改成功，请重新登录')
                        localStorage.removeItem('accessKey')
                        this.forgetForm = {password: '', username: '', checkPwd: ''}
                        this.forget = false
                        this.loginShow = true
                    }
                })
            } else {
                this.$message.error('两次密码不一致')
            }
        },

        regSuccess() {
            // 关闭弹框
            this.regTips = false
        },

        // 是否是活动进入送笔的
        givenCore(phone) {
            activityGiveCore(phone).then(res => {
                if (res.status) {
                    setTimeout(() => {
                        this.regTips = res.status
                        this.core = res.giveFund
                    }, 1000)

                } else {
                    this.regTips = false
                }

            }).catch(err => {
            })
        }
    },

    mounted() {
        getAgreement().then(res => {
            if (res) {
                this.label = res[0]
            }
        }).catch(error => {
        })
    },

    watch: {
        loginShow(newValue, oldValue) {
            if (newValue) {
                this.scanLoginInfo()
            }
        },
        active(newValue, oldValue) {
            if (newValue === 0) {
                this.scanLoginInfo()
            } else {
                window.clearInterval(this.chatLogin)
            }
        }
    },

}
</script>
<style scoped>
>>> .ant-modal-content {
    border-radius: 16px;
}

.forgetPassword {
    width: 100%;
    text-align: right;
    height: 20px;
    margin-top: -10px;
    margin-bottom: 10px;
    color: #505C69;
}
</style>