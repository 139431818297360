<template>
    <div>
        <a-modal
                :footer="null"
                width="420px"
                :dialog-style="{ top: '150px',borderRadius:'30px',padding:0}"
                :visible="regStatus"
                :maskClosable="false"
                @cancel="() => setModal1Visible(false)"
        >
            <div class="prompt_reg_success" >
                <div class="prompt_reg_success_img">
                    <div class="promeot_titles">注册成功</div>
                    <div style="width: 100%;display: flex;justify-content: center">
                        <img style="margin-bottom: 10px" src="../../assets/image/img/icon_core.png" alt="">
                    </div>
                    <div class="promeot_titles_tips">
                       <b>送您</b> <img src="../../assets/image/img/icon_cor.png" alt=""> <span> {{core}}标币</span>
                    </div>
                    <div class="promeot_titles_tips_bottom">
                        赶快去购买方案吧！
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    export default {
        name: "reg_tips",
        props:['regStatus','core'],

        methods:{
            setModal1Visible(){
                this.$emit('regSuccess',true)
            }
        }
    }
</script>


<style scoped>
    >>>.ant-modal-content {
        border-radius: 20px;
        padding: 0;
    }
    >>>.ant-modal-body {
        padding: 0;
    }
</style>

<style scoped lang="scss">
    .prompt_reg_success{
        width: 420px;
        height: 320px;
        background: #FFFFFF;
        border-radius: 10px;
        .prompt_reg_success_img{
            width: 100%;
            text-align: center;
            padding: 18px 0 0 0;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .promeot_titles{
                width: 100%;
                display: flex;
                justify-content: center;
                font-size: 24px;
                color: #2080e5;
                margin-bottom: 20px;
            }
            .promeot_titles_tips{
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                padding: 0 0 0 15px;
                box-sizing: border-box;
                b{
                    margin-right: 10px;
                    font-size: 20px;
                }
                span{
                    font-size: 24px;
                    color: #2080e5;
                }
            }
            .promeot_titles_tips_bottom{
                width: 100%;
                box-sizing: border-box;
                margin-top: 10px;
                color: #333333;
                font-size: 16px;
                text-align: center;
                padding: 0 0 0 25px;
            }

        }
        .prompt_reg_success_btn{
            width: 100%;
            text-align: center;
            margin-top: 38px;
            button{
                width: 224px;
                height: 44px;
                background: #2080E5;
                border-radius: 6px;
            }
        }
        .prompt_reg_success_doc_btn{
            width: 100%;
            text-align: center;
            margin-top: 20px;
            button{
                width: 224px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #2080E5;
            }
        }
    }
</style>