import axios from "axios";
import {addOrderRelation, promoteTo360, promoteToBaidu} from "@/api/page/promoted";
import {removeEmptyField} from "@/utils/resToData";
import {addViewCount, addViewsByUser} from "@/api/page/moduleTopic";
import {addBidViewsPhone, addViewsByUserPhone} from "@/api/phone/modelTopic";

export default function promoted() {
    getUserIP().then(res => {
        localStorage.setItem('locationIp', res.data.ip)
    })
    localStorage.setItem('locationUrl', location.href)
    let localUrl = location.href
    let reg_t0 = /[?]v[0-9]/g //百度
    if (reg_t0.test(location.href)) {
        let q = localUrl.split('?v')[1].split('/')[0] //检测来源
        let k = localUrl.split('?v')[1].split('/')[1] //后端需要的key
        let b = '' //百度需要的bd_vid
        let regLast = RegExp('/#')
        if (regLast.test(location.href)) {
            b = localUrl.split('?v')[1].split('/')[2].split('bd_vid=')[1]
        } else {
            b = localUrl.split('?v')[1].split('/')[2].split('bd_vid=')[1].slice(0, -1)
        }

    }

}

export async function getUserIP() {
    let s = await axios.get('https://api.ipify.org?format=json')
    return s
}

// 添加订单关系
export function updateOrderRecode(orderId, userId) {
    if (localStorage.getItem('promotedId')) {
        let promoteId = parseInt(localStorage.getItem('promotedId'))
        let form = {orderId: orderId, userId: userId, promoteId: promoteId}
        addOrderRelation(removeEmptyField(form)).then(() => {
        }).catch(() => {
        })
    }
}

// 百度推广
export function promoteBaiduInfo(status) {
    let locationUrl = localStorage.getItem('promoteUrl')
    let Regs = RegExp('bd_vid=')
    if (Regs.test(locationUrl)) {
        let s = {url: locationUrl, type: status}
        promoteToBaidu(s).then(res => {
        }).catch(err => {
        })
    }
    // let Regs_360 = RegExp('qhclickid=')
    // if (Regs_360.test(locationUrl) && status === 3) {
    //     promoteTo360(locationUrl).then(res => {
    //     }).catch(err => {
    //     })
    // }
}

// 浏览记录
export function readHistory(id) {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
        addViewsByUser(id).then(res => {
        }).catch(err => {
        })
    } else {
        addViewCount(id).then(res => {
        }).catch(err => {
        })
    }
}

// 移动端浏览记录
export function readByUserPhone(id) {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
        addViewsByUserPhone(id).then(res => {
        }).catch(err => {
        })
    } else {
        addBidViewsPhone(id).then(res => {
        }).catch(err => {
        })
    }
}

