import axios from "axios";

import router from "@/router";

const services = axios.create({
    timeout: 6000,
    // baseURL: '/',
    // baseURL:'/',
    // headers: {'Content-Type': 'application/json'}
})


services.interceptors.request.use(config => {

    let  Reg= RegExp('phone')
    if (Reg.test(location.href)){
        if (localStorage.getItem('accessKeyPhone')){
            config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessKeyPhone')
            config.headers['Content-Type'] = 'application/json';
        }
    }else{
        if (localStorage.getItem('accessKey')){
            config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessKey')
            config.headers['Content-Type'] = 'application/json';
        }
    }
        return config
    }, error => {
        Promise.reject(error)
    }
)

// services.interceptors.request.use(async (config) => {
//         let Reg = RegExp('phone');
//         if (Reg.test(location.href)) {
//             if (localStorage.getItem('accessKeyPhone')) {
//                 config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessKeyPhone');
//                 config.headers['Content-Type'] = 'application/json';
//             }
//         } else {
//             if (localStorage.getItem('accessKey')) {
//                 const token = localStorage.getItem('accessKey');
//                 const isTokenValid = await checkTokenValidity(token); // 检查token的有效性
//
//                 if (isTokenValid) {
//                     config.headers.Authorization = 'Bearer ' + token;
//                     config.headers['Content-Type'] = 'application/json';
//                 } else {
//                     // 进行token续签操作
//                     const newToken = await renewToken();
//                     if (newToken) {
//                         config.headers.Authorization = 'Bearer ' + newToken;
//                         config.headers['Content-Type'] = 'application/json';
//                         localStorage.setItem('accessKey', newToken);
//                     } else {
//                         // 续签失败，处理逻辑...
//                     }
//                 }
//             }
//         }
//         return config
//     }, error => {
//         Promise.reject(error)
//     }
// )
// function checkTokenValidity(token) {
//     // 假设这里的逻辑是检查token是否过期或无效
//     // 如果token有效，返回true；否则返回false
//     const isTokenValid = /* 假设的逻辑，检查token的有效性 */;
//     return isTokenValid;
// }
//
// // 进行token续签操作
// async function renewToken() {
//     try {
//         // 假设这里的逻辑是向服务器发送续签token的请求，并获取新的token
//         const response = await axios.post('/renew-token');
//         const newToken = response.data.token; // 假设从响应中获取新的token
//         return newToken;
//     } catch (error) {
//         // 处理续签失败的情况
//         console.error('Token renewal failed:', error);
//         return null;
//     }
// }

services.interceptors.response.use(
    response=>{

        if (response.data.code ===200 && response.status===200){
            return response.data
        }
        if (response.data.code===401 || response.status===401){
            if (localStorage.getItem('accessKeyPhone')){
                localStorage.removeItem('accessKeyPhone')
                router.push('/phone').catch(err=>{})
            }
            return  Promise.resolve(response)
        }
        if (response.data.code===500 || response.status===500){

            return  Promise.resolve(response)
        }
        if (response.data.code===405 || response.status===405){

            return  Promise.resolve(response)
        }

        return Promise.resolve(response);
    },
    error => {

        return Promise.reject(error)
    })


export default services
