import router from "@/router";
export default function clientWidthLogin () {
//     // 宽度：
// var w=window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
//     console.log(w)
//     // 高度：
// var h=window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
//
// if (w>1300){
//     router.push('/')
// }else{
//     router.push('/phone')
// }

}
