// 获取当前的推广地址
import {getCaption} from "@/utils/parse";
import {addAccessLog, addRegisterLog, getPromotionByCode} from "@/api/page/promoted";
import {decrypt, encrypt} from "@/utils/AES";
import {removeEmptyField} from "@/utils/resToData";

export default function GetPromoteAddress() {
    localStorage.setItem('promoteUrlPhone',location.href)
    localStorage.setItem('promoteUrl',location.href)
    localStorage.removeItem('phoneUrl')
    localStorage.removeItem('docUrl')
    localStorage.removeItem('historyUrl')
    let s = localStorage.getItem('userInfo')
    if (s){
        if (s.length<20){
            localStorage.removeItem('userInfo')
        }
    }


    let reg=/[?]v[0-9]/g
    if (reg.test(location.href)) {
        let s = getCaption(location.href, 'cn/?v')
        let arr = s.slice(6).split('/')[0]
        getPromotionByCode(arr).then(res=>{
            if (res){
                let promoted =decrypt(res)
                let p=getCaption(promoted.slice(12,-1).split(',')[0],'=')
                let k=getCaption(promoted.slice(12,-1).split(',')[5],'=')
                localStorage.setItem('promoted_id',parseInt(p))

            }else{
                localStorage.removeItem('promoted_id')
            }
        }).catch(error=>{})
    }

}
