<template>
    <div>
        <van-tabbar v-show="TabShow" route v-model="active">
            <van-tabbar-item to="/phone">
                <span>首页</span>
                <template #icon="props">
                    <img :src="props.active ? icon.active : icon.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/phoneUser">
                <span>我的</span>
                <template #icon="props">
                    <img :src="props.active ? user.active : user.inactive" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    import {getCaption} from "@/utils/parse";

    export default {
        data() {
            return {
                active: 0,
                TabShow:true,
                icon: {
                    active:  require('../../assets/image/h5/home.png'),
                    inactive: require('../../assets/image/h5/home_c.png') ,
                },
                user: {
                    active:  require('../../assets/image/h5/user.png'),
                    inactive: require('../../assets/image/h5/user_c.png') ,
                },
            };
        },

        mounted() {
            let reg=RegExp('phonePay|phoneRecharge|phoneView|phoneCollect|phoneAbout|phoneInfo|phonePain|phoneApply|phoneLogin|phoneSearch|phoneKeyword|phoneContent|phoneRechargePay|phoneCustom|phoneBuy|phoneDownload|phoneActive')
            if (reg.test(getCaption(location.href,'#/'))){
                this.TabShow=false
            }else{
                this.TabShow=true
            }
        },


        watch:{
            $route:{
                handler(newValue,oldValue){
                    let reg=RegExp('phonePay|phoneRecharge|phoneView|phoneCollect|phoneAbout|phoneInfo|phonePain|phoneApply|phoneLogin|phoneSearch|phoneKeyword|phoneContent|phoneRechargePay|phoneCustom|phoneBuy|phoneDownload|phoneActive')

                    localStorage.setItem('phoneUrl',getCaption(location.href,'#/'))
                    if (reg.test(newValue.path)){
                        this.TabShow=false
                    }else{
                        this.TabShow=true
                    }
                    switch (newValue.path) {
                        case '/phone':{
                            this.active=0
                            break
                        }
                        case '/phoneUser':{
                            this.active=1
                            break
                        }
                    }
                }
            },
            deep: true,
            immediate:true
        }
    };
</script>

<style scoped>
    >>>.van-tabbar {
        height: 0.5rem;
    }
    >>>.van-tabbar-item__icon img{
        height: 0.2rem;
    }
    >>>.van-tabbar-item__text {
        font-size: 0.12rem
    }
</style>