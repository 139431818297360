import service from "@/api/service";

// 首页banner图
export async function GetBanner() {
    let res = await service({
        url: '/api/moduleTopic/banner',
        method: 'post'
    })

    return res.data
}

// 获取个人信息
export async function getUserInfo() {
    let res = await service({
        url: '/api/sso/member/memberInfo',
        method: 'post'
    })

    return res.data
}

// 用户注册
export async function registerUserInfo(data,bdCode) {
    let res = await service({
        url: '/api/sso/member/register',
        method: 'post',
        data: data,
        headers: {
            'bdCode': bdCode // 设置请求头中的code
        },
    })

    return res.data
}

// 获取个人信息
export async function sendMessage(data) {
    let res = await service({
        url: '/api/pub/sendMessage',
        method: 'post',
        params: data
    })

    return res.data
}

export async function addLoginLog(value) {
    let res = await service({
        url: '/api/sso/member/addLoginLog',
        method: 'post',
        params: {code: value}
    })

    return res.data
}

// 短信登录
export async function loginByMessage(data,bdCode) {
    let res = await service({
        url: '/api/sso/member/loginByMessage',
        method: 'post',
        data: {phone: data},
        headers: {
            'bdCode': bdCode // 设置请求头中的code
        },
    })

    return res.data
}

// 账号密码登录
export async function loginForm(username, password) {
    let res = await service({
        url: '/api/sso/member/login',
        method: 'post',
        params: {username: username, password: password}
    })

    return res.data
}

// 我的统计
export async function myStatistics(data) {
    let res = await service({
        url: '/api/center/myStatistics',
        method: 'post',
        params: data
    })

    return res.data
}

// 我的充值
export async function chargeLogList(data) {
    let res = await service({
        url: '/api/center/chargeLogList',
        method: 'post',
        params: {pageSize: 10, pageNum: data.pageNum}
    })

    return res.data
}

// 我的浏览记录
export async function viewHistoryList(data) {
    let res = await service({
        url: '/api/center/viewHistoryList',
        method: 'post',
        params: {pageSize: 10, pageNum: data.pageNum}
    })

    return res.data
}

// 开票申请记录
export async function invoiceApplicationLog(data) {
    let res = await service({
        url: '/api/center/invoiceApplicationLog',
        method: 'post',
        params: {pageSize: 10, pageNum: data}
    })

    return res.data
}

// 开票申请记录
export async function invoiceAbleOrders() {
    let res = await service({
        url: '/api/center/invoiceAbleOrders',
        method: 'post',
    })

    return res.data
}

// 开票申请记录
export async function createApplication(data) {
    let res = await service({
        url: '/api/center/createApplication',
        method: 'post',
        data: data
    })

    return res.data
}

export async function checkHaveBuy(bidId) {
    let res = await service({
        url: '/api/order/checkHaveBuy',
        method: 'post',
        params: {bidId: bidId}
    })

    return res.data
}

// 获取数据字典
export async function getDataDictionary() {
    let res = await service({
        url: '/api/pub/getDataDictionary',
        method: 'post',
        data: {}
    })

    return res.data
}

// 获取数据字典
export async function collectList(data) {
    let res = await service({
        url: '/api/center/collectList',
        method: 'post',
        params: {pageSize: 10, pageNum: data}
    })

    return res.data
}

// 我的购买
export async function buyList(data) {
    let res = await service({
        url: '/api/center/buyList',
        method: 'post',
        params: {pageSize: 10, pageNum: data}
    })

    return res.data
}


// 通过手机号判定用户是否存在
export async function checkExistByPhone(data) {
    let res = await service({
        url: '/api/pub/checkExistByPhone',
        method: 'post',
        params: {phone: data}
    })

    return res.data
}

export async function phoneAddAccessLog(data) {
    let res = await service({
        url: '/api/pub/addAccessLog/',
        method: 'post',
        data: data
    })
    return res.data
}

// bucket下载文件
export async function getTemporaryAddress(bucket, fileName) {
    let res = await service({
        url: '/api/pub/getTemporaryAddress',
        method: 'post',
        params: {bucket, fileName: 'document/' + fileName}
    })

    return res.data
}

export async function getTemporaryAddress2(fileName) {
    let res = await service({
        url: '/api/pub/getTemporaryAddress2',
        method: 'post',
        data: {code: fileName}
    })

    return res.data
}


// bucket下载文件
export async function getTemporaryInvoice(bucket, fileName) {
    let res = await service({
        url: '/api/pub/getTemporaryAddress',
        method: 'post',
        params: {bucket, fileName: 'invoice/' + fileName}
    })

    return res.data
}
