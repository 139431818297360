<template>
    <div>
        <div class="phone_top">
            <div class="phone_left">
                <a-popover  trigger="click" placement="bottom">
                    <template slot="content">
                        <div class="phone_left_list">
                            <div class="btn_left" v-for="(item,index) in titleList" :key="index">
                                <a-button type="link" >{{item.title}} </a-button>
                            </div>
                        </div>
                    </template>
                    <a-button id="phone_nav" type="link">
                        <a-icon type="menu-unfold" />
                    </a-button>
                </a-popover>
            </div>
            <div class="phone_right">
                <span class="login" v-if="login===false"> <a-icon type="user" /> 登录</span>
                <span  class="login" v-if="login===false"> <a-icon type="user-add" /> 注册</span>
                <a-popover  trigger="click" v-if="login" placement="bottom">
                    <template slot="content">
                        <div class="phone_left_list">
                            <div class="btn_left" v-for="(item,index) in titleList" :key="index">
                                <a-button type="link" >{{item.title}} </a-button>
                            </div>
                        </div>
                    </template>
                    <span>
                        超级管理员
                    </span>
                    <span>
                        <a-icon type="user" />
                    </span>
                </a-popover>
            </div>

        </div>
    </div>
</template>

<script>
    import data from "@/data/data";
    export default {
        name: "nav_phone",
        props:['navTop','login'],
        data(){
            return{
                titleList:data.navList,
                logo:data.logoTop, //目前为置顶的状态
                logoLeave:data.logo
            }
        }
    }
</script>

<style scoped lang="scss">
    /*phone*/
    .phone_top{
        width: 100%;
        height:0.5rem;
        background: #fff;
        box-shadow: 1px 1px 0.2rem 0 #e5eaf6;
        display: flex;
        justify-content: space-between;
        align-content: center;
        .phone_left{
            width: 16vw;
            height:0.5rem;
            display: flex;
            /*justify-content: center;*/
            align-items: center;
            #phone_nav{
                font-size: 0.18rem;
                color: #666666;
            }
            .phone_left_list{
                width: 8vw;
                min-height: 8vh;
                color: #666666 !important;
                .btn_left{
                    color: #666666 !important;
                }

            }
        }
        .phone_right{
            text-align: right;
            justify-content: right;
            padding: 0 0.2rem 0 0;
            box-sizing: border-box;
            font-size: 0.15rem;
            width: 50vw;
            /*background: red;*/
            height:0.5rem;
            display: flex;
            align-items: center;
            .login{
                font-size: 0.1rem;
                margin-right: 1rem;
            }
            span{
                font-size: 0.15rem;
                margin-left: 2vw;
            }
        }
    }

    ::v-deep .ant-btn-link{
        color: #666666;
    }
</style>
