// /**
//  *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
//  *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
//  *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
//  *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。
//  */
var OSS = require('ali-oss');
export const client=new OSS({
    // https://oss-cn-shanghai.aliyuncs.com yunlang-bid-document
    region: 'oss-cn-shanghai',
    accessKeyId: 'LTAI5tBVfmXpghCPqKjcFj1g',
    accessKeySecret: 'ZwNLoWJpd1z5VK2csfuHnYuhs25bm2',
    bucket: 'yuebiaoquan-bid-document',
    // bucket: 'yunlang-bid-document-dev',
    secure: true
})


