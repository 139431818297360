import {parseTime} from "@/utils/parse";
import store from '@/store/index'
import {addUserBehaviorLog, getDataDictionary} from "@/api/page/public";
import {decrypt} from "@/utils/AES";

export function accessGetBanner(res) {
    let s = []
    if (res.length > 0) {
        // res.forEach(val => {
        //     switch (val.place) {
        //         // 首页
        //         case  1: {
        //             if (val.timeType === 2) {
        //                 s.push({
        //                     id: val.id,
        //                     imgUrl: val.imgUrl,
        //                     name: val.name,
        //                     url: val.url,
        //                     status: 1,
        //                     sort: val.sort,
        //                     place: 1
        //                 })
        //             } else {
        //                 if (val.beginTime - Date.parse(new Date()) < 0 && val.endTime - Date.parse(new Date()) > 0) {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 1,
        //                         place: 1,
        //                         sort: val.sort
        //                     })
        //                 } else {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 0,
        //                         place: 1,
        //                         sort: val.sort
        //                     })
        //                 }
        //             }
        //             break
        //         }
        //         // 首页中部
        //         case  2 : {
        //             if (val.timeType === 2) {
        //                 s.push({id: val.id, imgUrl: val.imgUrl, name: val.name, url: val.url, status: 1, place: 2})
        //             } else {
        //                 if (val.beginTime - Date.parse(new Date()) < 0 && val.endTime - Date.parse(new Date()) > 0) {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 1,
        //                         place: 2,
        //                         sort: val.sort
        //                     })
        //                 } else {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 0,
        //                         place: 2,
        //                         sort: val.sort
        //                     })
        //                 }
        //             }
        //             break
        //         }
        //         // 搜索页
        //         case 3 : {
        //             if (val.timeType === 2) {
        //                 s.push({id: val.id, imgUrl: val.imgUrl, name: val.name, url: val.url, status: 1, place: 3})
        //             } else {
        //                 if (val.beginTime - Date.parse(new Date()) < 0 && val.endTime - Date.parse(new Date()) > 0) {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 1,
        //                         place: 3,
        //                         sort: val.sort
        //                     })
        //                 } else {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 0,
        //                         place: 3,
        //                         sort: val.sort
        //                     })
        //                 }
        //             }
        //             break
        //         }
        //         // 详情页
        //         case 4 : {
        //             if (val.timeType === 2) {
        //                 s.push({id: val.id, imgUrl: val.imgUrl, name: val.name, url: val.url, status: 1, place: 4})
        //             } else {
        //                 if (val.beginTime - Date.parse(new Date()) < 0 && val.endTime - Date.parse(new Date()) > 0) {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 1,
        //                         place: 4,
        //                         sort: val.sort
        //                     })
        //                 } else {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 0,
        //                         place: 4,
        //                         sort: val.sort
        //                     })
        //                 }
        //             }
        //             break
        //         }
        //         // 个人中心
        //         case 5 : {
        //             if (val.timeType === 2) {
        //                 s.push({id: val.id, imgUrl: val.imgUrl, name: val.name, url: val.url, status: 1, place: 5})
        //             } else {
        //                 if (val.beginTime - Date.parse(new Date()) < 0 && val.endTime - Date.parse(new Date()) > 0) {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 1,
        //                         place: 5,
        //                         sort: val.sort
        //                     })
        //                 } else {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 0,
        //                         place: 5,
        //                         sort: val.sort
        //                     })
        //                 }
        //             }
        //             break
        //         }
        //         // 定制banner
        //         case 6 : {
        //             if (val.timeType === 2) {
        //                 s.push({id: val.id, imgUrl: val.imgUrl, name: val.name, url: val.url, status: 1, place: 6})
        //             } else {
        //                 if (val.beginTime - Date.parse(new Date()) < 0 && val.endTime - Date.parse(new Date()) > 0) {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 1,
        //                         place: 6,
        //                         sort: val.sort
        //                     })
        //                 } else {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 0,
        //                         place: 6,
        //                         sort: val.sort
        //                     })
        //                 }
        //             }
        //             break
        //         }
        //         // 定制中部
        //         case 7 : {
        //             if (val.timeType === 2) {
        //                 s.push({id: val.id, imgUrl: val.imgUrl, name: val.name, url: val.url, status: 1, place: 7})
        //             } else {
        //                 if (val.beginTime - Date.parse(new Date()) < 0 && val.endTime - Date.parse(new Date()) > 0) {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 1,
        //                         place: 7,
        //                         sort: val.sort
        //                     })
        //                 } else {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 0,
        //                         place: 7,
        //                         sort: val.sort
        //                     })
        //                 }
        //             }
        //             break
        //         }
        //         // 模糊搜索
        //         case 8 : {
        //             if (val.timeType === 2) {
        //                 s.push({id: val.id, imgUrl: val.imgUrl, name: val.name, url: val.url, status: 1, place: 8})
        //             } else {
        //                 if (val.beginTime - Date.parse(new Date()) < 0 && val.endTime - Date.parse(new Date()) > 0) {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 1,
        //                         place: 8,
        //                         sort: val.sort
        //                     })
        //                 } else {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 0,
        //                         place: 8,
        //                         sort: val.sort
        //                     })
        //                 }
        //             }
        //             break
        //         }
        //         // h5
        //         case 9 : {
        //             if (val.timeType === 2) {
        //                 s.push({id: val.id, imgUrl: val.imgUrl, name: val.name, url: val.url, status: 1, place: 9})
        //             } else {
        //                 if (val.beginTime - Date.parse(new Date()) < 0 && val.endTime - Date.parse(new Date()) > 0) {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 1,
        //                         place: 9,
        //                         sort: val.sort
        //                     })
        //                 } else {
        //                     s.push({
        //                         id: val.id,
        //                         imgUrl: val.imgUrl,
        //                         name: val.name,
        //                         url: val.url,
        //                         status: 0,
        //                         place: 9,
        //                         sort: val.sort
        //                     })
        //                 }
        //             }
        //             break
        //         }
        //     }
        // })

        return res
    }
}

export function timeToTimes(arr) {
    if (arr.freeBidList.length > 0) {
        arr.freeBidList.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }
    if (arr.freeContractList.length > 0) {
        arr.freeContractList.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }
    if (arr.freeRegulationList.length > 0) {
        arr.freeRegulationList.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }
    if (arr.newestList.length > 0) {
        arr.newestList.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }
    if (arr.newestListContract.length > 0) {
        arr.newestListContract.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }
    if (arr.newestListRegulation.length > 0) {
        arr.newestListRegulation.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }
    if (arr.popList.length > 0) {
        arr.popList.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }
    if (arr.popListContract.length > 0) {
        arr.popListContract.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }

    if (arr.popListRegulation.length > 0) {
        arr.popListRegulation.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }

    if (arr.qualityList.length > 0) {
        arr.qualityList.forEach(value => {
            value.createTime = parseTime(value.createTime).slice(0, 10)
        })
    }

    return arr
}

export default function accessDict() {
    getDataDictionary().then(res => {
        store.state.dictory = res
    }).catch(err=>{})
}

// 数据字典的数据替换
export function createToDict(items) {
    return items.map(item => {
        const result = {...item, label: item.name, value: item.value}
        if (item.sonList) {
            result.sonList = createToDict(item.sonList)
        }
        return result
    })
}


// 获取数据字典
export function storeDictionary(code) {

    let array = createToDict(store.state.dictory)

    if (array.length > 0) {
        let result = array.filter(value => {
            return value.code === code
        });
        return result[0].sonList
    } else {
        return []
    }
}

// 分页
export function pageToMinPage(e) {

    if (e===0){
        return {minPage:'',maxPage:''}
    }else if (e===1){
        return {minPage:200,maxPage:300}
    }else if (e===2){
        return {minPage:300,maxPage:400}
    }else if (e===3){
        return {minPage:400,maxPage:500}
    }else if (e===4){
        return {minPage:500,maxPage:10000}
    }
}

// 图片转文档
export function docToPicture(url,doc,start,end) {
    let arr=[]
    let j=start
    for (let i=start;i<=end;i++){
        let reg = RegExp('doc')
        let reg2 = RegExp('ppt')
        let name =''
        if (reg.test(doc)){
            name= doc.split('.doc')[0]
        }else if (reg2.test(doc)){
            name= doc.split('.ppt')[0]
        }

        arr.push(url+'/document/image/'+name+`/${name}_${j++}.png`)
        // arr.push(url+'/document/image/'+doc.split('.doc')[0]+`/${doc.split('.doc')[0]}_${j++}.png!biaoxiaozhu`)
    }
    return arr
}

// 统计用户的行为
export function addBehavior(title,name,url,beginTime,endTime){
    let s = localStorage.getItem('userInfo')
    if (s){
        beginTime ? beginTime : beginTime=Date.parse(new Date())
        endTime ? endTime : endTime=Date.parse(new Date())+1000
        let form = {userId:JSON.parse(decrypt(s)).id,title,name,url,beginTime ,endTime}
        addUserBehaviorLog(form).then(res=>{}).catch(err=>{})
    }else{

    }

}